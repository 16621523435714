import { render, staticRenderFns } from "./User__BasicInfo.vue?vue&type=template&id=18c9f783&scoped=true&"
import script from "./User__BasicInfo.vue?vue&type=script&lang=js&"
export * from "./User__BasicInfo.vue?vue&type=script&lang=js&"
import style0 from "./User__BasicInfo.vue?vue&type=style&index=0&id=18c9f783&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c9f783",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VChip,VIcon,VList,VListItem,VMenu})
