import axios from "axios";

const OPERATION_BUY = 'buy';
const OPERATION_SELL = 'sell'; // Not used for now

const TRANSACTION_TYPE_AFFILIATION_MA = 'affiliation_ma';
const TRANSACTION_TYPE_REAFFILIATION_MA = 'reaffiliation_ma';
const TRANSACTION_TYPE_CREATE_LICENCE = 'create_licence';
const TRANSACTION_TYPE_CREATE_LICENCE_HC = 'create_licence_hc';
const TRANSACTION_TYPE_UPDATE_LICENCE = 'update_licence';
const TRANSACTION_TYPE_VOYAGE = 'voyage';
const TRANSACTION_TYPE_CANCEL_LICENCE = 'cancel_licence';

const PAYMENT_TYPE_CASH = 'cash'; // Not used for now
const PAYMENT_TYPE_LEMONWAY = 'lemonway';

const PRODUCT_TYPE_LICENCE = 'licence';
const PRODUCT_TYPE_LICENCE_HC = 'licence_hc';
const PRODUCT_TYPE_ASSURANCE = 'assurance';
const PRODUCT_TYPE_ASSURANCE_VOYAGE = 'voyage';
const PRODUCT_TYPE_REAFFILIATION_MA = 'reaffiliation_ma';
const PRODUCT_TYPE_INSCRIPTION_MA = 'inscription_ma';

const OPTION_TYPE_ASSURANCE = 'assurance';

const STATUS_CREATED = 'created';
const STATUS_WAITING_PAYMENT = 'waiting_payment';
const STATUS_PROCESSING = 'processing';
const STATUS_SUCCESS = 'success';
const STATUS_ERROR = 'error';
const STATUS_CANCELED = 'canceled';
const STATUS_PAYMENT_FAILED = 'payment_failed';
const STATUS_RETRIED = 'retried';

const TOKEN_NAME = process.env.VUE_APP_AUTH_TOKEN_NAME;

const headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_NAME),
};

const getTransactions = async (token) => {
  let getHeaders = headers;
  getHeaders['Authorization'] = 'Bearer ' + token;

  try {
    const response = await axios.get(
      process.env.VUE_APP_FFME_BACK_URL + "/api/transactions",
      {
        params: {
          'and[required]': true,
          'and[or][status][0]': STATUS_CREATED,
          'and[or][status][1]': STATUS_WAITING_PAYMENT,
          'and[or][status][2]': STATUS_PROCESSING,
          'and[or][status][3]': STATUS_ERROR,
          'and[or][status][4]': STATUS_CANCELED,
          'and[or][status][5]': STATUS_PAYMENT_FAILED,
          'order[createdAt]': 'desc',
        },
        headers: headers
      }
    );

    const json = await response.data;

    return { ok: true, data: json };
  } catch (error) {
    return { ok: false };
  }
};

const insertTransaction = async (params) => {
  try {
    const response = await axios.post(
      process.env.VUE_APP_FFME_BACK_URL + "/api/transactions",
      params,
      {
        headers: params['type'] && (params['type'] === TRANSACTION_TYPE_AFFILIATION_MA || params['type'] === TRANSACTION_TYPE_CREATE_LICENCE_HC) ? undefined : headers
      }
    );

    const json = await response.data;

    return { ok: true, data: json };
  } catch (error) {
    return { ok: false, data: error.response.data };
  }
};

const successTransaction = async (transactionId) => {
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_FFME_BACK_URL}/api/transactions/${transactionId}/success`,
      {},
      {
        headers: headers
      }
    );

    const json = await response.data;

    return { ok: true, data: json };
  } catch (error) {
    return { ok: false };
  }
};

const createVoyage = async (
  amount,
  productId,
  userId,
  seasonId,
  departureDate,
  returnDate,
  description,
  activities
) => {
  try {
    const response = await axios.post(
      process.env.VUE_APP_FFME_BACK_URL + "/api/voyage",
      {
        "userId": userId,
        "seasonId": seasonId,
        "countryId": productId,
        "amount": amount,
        "departure": departureDate,
        "return": returnDate,
        "description": description,
        "activities": activities,
      },
      {
        headers: headers
      }
    );

    const json = await response.data;

    return { ok: true, data: json };
  } catch (error) {
    return { ok: false };
  }
};

const getTransactionOptionAssuranceBody = (amount, product, productOptions, userId, seasonId) => {
  return {
    "operation": OPERATION_BUY,
    "paymentType": PAYMENT_TYPE_LEMONWAY,
    "utilisateurId": userId,
    "season": '/api/seasons/' + seasonId,
    "product": product,
    "productOptions": productOptions,
    "amount": amount,
  };
}

const getTransactionReAffiliationMABody = (amount, productId, optionId, userId, seasonId, structureId) => {
  let transactionItem = {
    "amount": amount,
    "product": `/api/products/${productId}`,
    "additionalDatas": {
      "structureId": structureId
    }
  }

  if (optionId !== undefined) {
    transactionItem.options = [`/api/options/${optionId}`];
  }

  return {
    "type": TRANSACTION_TYPE_REAFFILIATION_MA,
    "season": `/api/seasons/${seasonId}`,
    "operation": OPERATION_BUY,
    "paymentType": PAYMENT_TYPE_LEMONWAY,
    "amount": amount,
    "user": `/api/uti_utilisateurs/${userId}`,
    "transactionItems": [transactionItem],
  };
}

const getTransactionAffiliationMABody = (amount, productId, optionId, seasonId, affiliationId, structureName) => {
  let transactionItem = {
    "amount": amount,
    "product": `/api/products/${productId}`,
    "additionalDatas": {
      "affiTempId": affiliationId,
      "structureName": structureName,
    }
  }

  if (optionId !== undefined) {
    transactionItem.options = [`/api/options/${optionId}`];
  }

  return {
    "type": TRANSACTION_TYPE_AFFILIATION_MA,
    "season": `/api/seasons/${seasonId}`,
    "operation": OPERATION_BUY,
    "paymentType": PAYMENT_TYPE_LEMONWAY,
    "amount": amount,
    "transactionItems": [transactionItem]
  }
}

const getTransactionAssuranceVoyageBody = (
  amount,
  productId,
  userId,
  seasonId,
  departureDate,
  returnDate,
  description,
  activities
) => {
  return {
    "operation": OPERATION_BUY,
    "paymentType": PAYMENT_TYPE_LEMONWAY,
    "utilisateurId": userId,
    "season": '/api/seasons/' + seasonId,
    "product": {
      "product_type": PRODUCT_TYPE_ASSURANCE_VOYAGE,
      "product_id": productId,
    },
    "additionalDatas": {
      'departure': departureDate,
      'return': returnDate,
      'description': description,
      'activities': activities,
    },
    "amount": amount,
  };
}

const getTransactionLicenceDematerialiseBody = (
  amount,
  productId,
  userId,
  seasonId,
  assuranceOptions,
) => {
  const options = [];

  assuranceOptions.forEach((option) => {
    options.push({
      "option_type": OPTION_TYPE_ASSURANCE,
      "option_id": option,
    });
  });

  return {
    "operation": OPERATION_BUY,
    "paymentType": PAYMENT_TYPE_LEMONWAY,
    "utilisateurId": userId,
    "season": '/api/seasons/' + seasonId,
    "product": {
      "product_type": PRODUCT_TYPE_LICENCE,
      "product_id": productId,
    },
    "productOptions": options,
    "amount": amount,
  };
}

const getTransactionLicenceHCBody = (
  amount,
  product,
  options,
  seasonId,
  userTempId,
) => {
  let formattedOptions = [];
  formattedOptions = options.map(option => `/api/options/${option}`);

  return {
    "type": TRANSACTION_TYPE_CREATE_LICENCE_HC,
    "season": `/api/seasons/${seasonId}`,
    "operation": OPERATION_BUY,
    "paymentType": PAYMENT_TYPE_LEMONWAY,
    "amount": amount,
    "transactionItems": [
      {
        "amount": amount,
        "product": `/api/products/${product}`,
        "options": formattedOptions,
        "additionalDatas": {
          "userTempId": userTempId
        }
      }
    ]
  }
}

const getTransactionCancelLicenceBody = (licence, userId) => {
  let formattedOptions = [];
  formattedOptions = licence.licenceOptions.map(licenceOption => `/api/options/${licenceOption.option.id}`);

  let paymentType = PAYMENT_TYPE_CASH;

  if (licence.product.slug.includes('hors_club')) {
    paymentType = PAYMENT_TYPE_LEMONWAY;
  }

  return {
    "type": TRANSACTION_TYPE_CANCEL_LICENCE,
    "season": `/api/seasons/${licence.season.id}`,
    "user": `/api/uti_utilisateurs/${userId}`,
    "operation": OPERATION_SELL,
    "paymentType": paymentType,
    "amount": 0,
    "transactionItems": [
      {
        "amount": 0,
        "product": `/api/products/${licence.product.id}`,
        "options": formattedOptions,
        "additionalDatas": {
          "licenceId": licence.id
        }
      }
    ]
  }
}


export {
  getTransactions,
  insertTransaction,
  successTransaction,
  getTransactionOptionAssuranceBody,
  getTransactionReAffiliationMABody,
  getTransactionAffiliationMABody,
  getTransactionAssuranceVoyageBody,
  getTransactionLicenceDematerialiseBody,
  getTransactionLicenceHCBody,
  getTransactionCancelLicenceBody,
  createVoyage,
  PRODUCT_TYPE_ASSURANCE,
  PRODUCT_TYPE_ASSURANCE_VOYAGE,
  PRODUCT_TYPE_LICENCE,
  PRODUCT_TYPE_LICENCE_HC,
  PRODUCT_TYPE_REAFFILIATION_MA,
  PRODUCT_TYPE_INSCRIPTION_MA,
  OPTION_TYPE_ASSURANCE,
};
