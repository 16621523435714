import axios from "axios";

const TOKEN_NAME = process.env.VUE_APP_AUTH_TOKEN_NAME;

const getHeaders = () => {
  if (localStorage.getItem(TOKEN_NAME)) {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_NAME),
    };
  }

  return {
    'Content-Type': 'application/json',
  };
}

export const getSeasons = async (payload) => {
  return await axios
    .get(process.env.VUE_APP_FFME_BACK_URL + "/api/seasons",
      {
        params: payload,
        headers: getHeaders()
      }
    )
    .then((response) => {
      return response.data?.['hydra:member'];
    })
    .catch((error) => {
    });
}

export const getSeasonDocuments = async (id) => {
  return await axios
    .get(`${process.env.VUE_APP_FFME_BACK_URL}/api/seasons/${id}/documents`,
      {
        headers: getHeaders()
      }
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
    });
}
