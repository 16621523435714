<template>
  <v-col v-if="mounted">
    <div class="px-4 py-3">
      <h5>Cotisation {{ structure.NomStructure }} {{ SaisonName }}</h5>
      <div v-if="tarifs.cotisation_licence">
        <span class="font-weight-bold">Cotisation licence:</span>
        {{ tarifs.cotisation_licence }}€
      </div>
      <div v-else>
        <span class="font-weight-bold">Aucun tarif renseigné</span>
      </div>
    </div>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    mounted: false,
    tarifsCTCategorie: [],
    tarifsCT: [],

    tarifs: {},
    structure: null,
    loading: false,
  }),

  async created() {
    await this.setData();
    this.mounted = true;
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison"]),

    SaisonName() {
      return this.currentSaison.label;
    },
  },

  methods: {
    ...mapActions("basics", ["getTarifsCategorieCT", "getTarifsCT"]),
    ...mapActions("structure", ["getStructureByID"]),

    async setData() {
      // get the categorie tarif
      this.tarifsCTCategorie = await this.getTarifsCategorieCT();
      // get the actual tarif for this saison
      this.tarifsCT = await this.getTarifsCT({
        ID_Saison: this.currentSaison.id,
        ID_Structure: this.context.ID_Structure,
      });
      // set the model
      this.structure = await this.getStructureByID(this.context.ID_Structure);

      this.setTarifs(this.tarifsCT);

      return true;
    },

    // get tarfif by slug
    getTarif(slug) {
      let tarif = null;
      tarif = this.tarifsCT.find(
        (tarif) => tarif.LIST_TarifCategorie.SlugCategorieTarif == slug
      );
      if (tarif) return tarif;
      return null;
    },

    // get a montant tarif by slug
    getTarifMontant(slug) {
      let tarif = this.getTarif(slug);
      if (tarif) return tarif.MontantUnitaire;
      return null;
    },

    // Set the tarif for the model
    setTarifs() {
      this.tarifs.cotisation_licence = this.getTarifMontant(
        "cotisation_licence"
      );
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>
