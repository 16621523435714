<template>
  <v-dialog
    v-if="modalIsOpen"
    v-model="modalIsOpen"
    max-width="600px"
    :key="formKey"
    persistent
  >
    <v-tabs background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>{{ modalTitle }}</v-tab>
      <v-icon class="close-icon" @click="close">mdi-close</v-icon>
    </v-tabs>
    <div class="modal__content">
      <v-tabs-items>
        <v-card flat>
          <ValidateForm
            v-if="mounted"
            @validated="openConfirmModal"
            :loading="loading"
            style="width: 100%;"
            :showFieldObl="false"
          >
            <!-- <v-col v-if="dataFromLastSeason"
              >Informations de la saison précedente!</v-col
            > -->

            <v-col cols="12" v-if="hasGlobalError">
              <ul>
                <li
                  style="font-size: 0.8rem; color: red;"
                  v-for="(errorMessage, index) in globaleErrorMessage"
                  :key="index"
                >
                  {{ errorMessage }}
                </li>
              </ul>
              <v-card-actions class="px-10 pb-4">
                <v-spacer></v-spacer>
                <v-btn @click.prevent="close()" color="red" class="mx-2" rounded
                  >Annuler</v-btn
                >
              </v-card-actions>
            </v-col>

            <v-col v-else cols="12">
              <selectInput
                :items="saisonsList"
                v-model="saison"
                rules="required"
                item_text="NomSaison"
                @onChange="onSaisonSelected"
                item_value="id"
                label="Saison"
              ></selectInput>
              <!-- <selectInput
                :items="tarifsFamilleList"
                v-model="tarif"
                rules="required"
                item_text="NomTarif"
                @onChange="onTarifSelected"
                item_value="id"
                label="Catégorie de tarif"
              ></selectInput> -->
              <selectInput
                :items="licenceTypeList"
                v-model="licence"
                @onChange="licenceChange"
                rules="required"
                item_text="NomTypeLicence"
                item_value="id"
                label="Type de licence"
              ></selectInput>
              <v-row v-if="this.licenceCount && this.licenceCount > 0">
                <v-col cols="3"></v-col>
                <v-col cols="9" style="padding-top: 2px; padding-bottom: 2px;">
                  <p style="font-family: 'Roboto'; font-style: normal; margin-bottom: 0; font-weight: 100; font-size: 12px; line-height: 16px;">
                    La saisie de licence famille est disponible via la saisie
                    de licence manuelle
                  </p>
                </v-col>
              </v-row>
              <selectInput
                :items="assurancesListFiltered()"
                v-model="assurance"
                @onChange="assuranceChange"
                item_text="NomTypeAssurance"
                item_value="id"
                label="Type d'assurance"
                rules="required"
              ></selectInput>
              <selectInput
                :items="assurancesOptionListFiltered()"
                v-model="optionAssurances"
                @onChange="assuranceOptionChange"
                item_text="NomTypeAssurance"
                item_value="id"
                label="Options d'assurance"
                :multiple="true"
              ></selectInput>
              <selectInput
                v-if="hasNotAssuRc"
                :items="assurancesOptionIJListFiltered()"
                v-model="optionAssurancesIJ"
                @onChange="assuranceOptionIJChange"
                item_text="NomTypeAssurance"
                item_value="id"
                label="Indemnité journalière"
              ></selectInput>
              <v-row class="checkbox-pratiquant">
                <v-col cols="3" class="field__label-column">
                  <label>Non-pratiquant:</label>
                </v-col>
                <v-col cols="9">
                  <v-checkbox
                    style="width: 100%;"
                    v-model="isNonPratiquant"
                    label=""
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <p v-if="isNonPratiquant" class="non-pratiquant-warning">
                    En application du Code de la Santé et du Code du Sport la
                    licence non-pratiquant interdit à son titulaire la pratique
                    de toute activité sportive quel qu’en soit la nature
                    (sportif, entraineur, juge, arbitre, assureur, équipeur).
                  </p>
                </v-col>
                <v-col cols="4">
                  <v-card-actions class="px-10 pb-4">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click.prevent="close()"
                      color="red"
                      class="mx-2 white--text"
                      rounded
                      >Annuler</v-btn
                    >
                    <v-btn
                      type="submit"
                      :loading="loading"
                      :disabled="canSubmit"
                      small
                      rounded
                      class="btn btn-primary"
                      >Enregistrer</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-col>
          </ValidateForm>
          <div v-else class="py-4 text-center">Chargement...</div>
        </v-card>
      </v-tabs-items>
    </div>

    <modalConfirmTarif
      v-if="modalConfirmTarifIsOpen"
      :modalIsOpen="modalConfirmTarifIsOpen"
      @onClose="modalConfirmTarifIsOpen = false"
      @onConfirm="submit"
      :users="users"
      :data="{
        tarif: tarif,
        saison: saison,
        licence: licence,
        assurance: assurance,
        optionAssurance: optionAssurances,
        optionAssurancesIJ: optionAssurancesIJ,
        nomGroupe: null,
        optionAdhesionID: OptionsAdhesions,
        optionAdhesion: optionAdhesion,
      }"
      :licenceCount="licenceCount"
    />
    <PrintFacture ref="facture" :print="print"></PrintFacture>
  </v-dialog>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable  no-unexpected-multiline */
import ValidateForm from "@/components/utils/form.vue";
import selectInput from "@/components/utils/select.vue";

import modalUserDetail from "@/components/utils/modals/User/User__Modal.vue";

import modalConfirmTarif from "@/components/utils/modals/Licence/LicenceConfirm__Modal.vue";
// import InputText from "@/components/utils/input.vue"
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import { sleep, send_email } from "@/plugins/utils";
import { mapActions, mapGetters } from "vuex";

import { bus } from "@/main";
import PrintFacture from "@/components/Structure/Finance/PrintFacture.vue";
import {formatFluxFromLicenceModal, insertFlux} from "@/plugins/fluxService";
import { validate } from "@/constants/doc_status";

export default {
  components: { ValidateForm, selectInput, modalConfirmTarif, PrintFacture },

  data: () => ({
    loading: false,
    mounted: false,
    formKey: 0,
    users: [],
    modalConfirmTarifIsOpen: false,
    change: {
      tarif: false,
      assurance: false,
      optionAssurances: false,
      optionAssurancesIJ: false,
      isNonPratiquant: false,
    },

    hasGlobalError: false,
    globaleErrorMessage: [],

    saisonsList: [],
    saison: null,

    tarifsFamilleList: [],
    tarif: null,

    allLicences: [],
    allLicenceStructures: [],
    licenceTypeList: [],
    licence: null,

    assurancesList: [],
    assurance: null,
    optionAssurances: [],

    isNonPratiquant: false,
    oldIsNonPratiquant: false,

    optionAssurancesIJ: null,

    OptionsAdhesions: [],
    optionAdhesion: null,
    test: [],
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
  }),

  async created() {
    this.users = this.itemsSelected ? this.itemsSelected : [this.user];
    await this.setData();
    this.mounted = true;
  },

  watch: {
    isNonPratiquant(value, oldValue) {
      if (value !== this.oldIsNonPratiquant) {
        this.change.isNonPratiquant = true;
      } else {
        this.change.isNonPratiquant = false;
      }
    },
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison", "selectedSaison"]),
    ...mapGetters("structure", [
      "currentStructure",
      "currentStructureId",
    ]),
    ...mapGetters('user', ['currentUserId']),

    modalTitle() {
      if (this.users.length == 1) {
        return (
          "Adhésion de " +
          this.users[0].UTI_Utilisateur.CT_Nom +
          " " +
          this.users[0].UTI_Utilisateur.CT_Prenom
        );
      } else {
        return "LICENCES EN SERIE";
      }
    },

    licenceSaison() {
      if (this.itemsSelected) return false;
      return this.allLicences.find((lic) => lic.ID_Saison == this.saison && lic.EST_Actif);
    },

    canSubmit() {
      if (!this.licenceSaison) return false;
      if (
        this.change.tarif == true ||
        this.change.assurance == true ||
        this.change.optionAssurances == true ||
        this.change.optionAssurancesIJ == true ||
        this.change.isNonPratiquant == true
      )
        return false;
      return true;
    },

    hasNotAssuRc() {
      if (!this.assurance) return false;
      let rc = this.assurancesList.find(
        (assu) => assu.SlugTypeAssurance == "assurance_rc"
      ).id;
      if (this.assurance != rc) return true;
      return false;
    },
  },

  methods: {
    ...mapActions("structure", [
      "updateLicenceUtilisateurStructureList",
      "getAffiliationTarifStructure",
      "updateLastLicenceUtilisateur",
      "getGroupeCreneauFullData",
      "addUserToStructureOnPrimoAffi",
    ]),
    ...mapActions("utilisateurs", [
      "getLicenceUtilisateur",
      "getFluxFinancierUtilisateur",
      "createFluxFinancierDetail",
      "deleteFluxFinancierDetail",
      "getUtilisateur",
      "deleteLicenceAssurance",
      "updateUser",
      "updateUserESTLicencie",
      "searchUserByNameStructureAndSaison",
      "searchAddressesAndUsersByAddressName",
      "getUtilisateurAdresse",
    ]),
    ...mapActions("basics", ["getListTypeLicence", "getListTypeAssurances"]),
    ...mapActions("tarifs", ["getTarifsAssurance"]),

    licenceChange(licence) {
      if (this.licenceSaison) {
        console.log("this.licenceTypeList :>> ", this.licenceTypeList);
        let oldLicenceID = this.licenceTypeList.find(
          (lic) => lic.id == this.licenceSaison.ID_TypeLicence && lic.EST_Actif
        )?.id;
        if (oldLicenceID !== undefined && oldLicenceID === licence) {
          this.change.licence = true;
        }
      }
    },

    assuranceChange(assurance) {
      // if RC remove options
      if (assurance === 14) {
        // Set option assurances
        this.optionAssurances = this.optionAssurances.filter(
            (assu) =>
                assu === 25
        );
        this.optionAssurancesIJ = null;
      }

      if (this.licenceSaison && this.licenceSaison.UTI_LicenceAssurance) {
        // Set main assurande
        let oldassuranceID = this.licenceSaison.UTI_LicenceAssurance.find(
          (assu) => assu.ASSU_AssuranceType.EST_Option == false
        ).ASSU_AssuranceType.id;
        //Check if we have a different assurance to toggle change prop
        this.change.assurance = false;
        if (oldassuranceID != assurance) {
          this.change.assurance = true;
        }
      }
    },

    assuranceOptionChange(assurancesIDS) {
      if (this.licenceSaison && this.licenceSaison.UTI_LicenceAssurance) {
        // Set main assurande
        this.change.optionAssurances = false;

        //Check if we have a different assurance to toggle change prop
        assurancesIDS.forEach((assu) => {
          let exist = this.licenceSaison.UTI_LicenceAssurance.filter(
            (oldAssu) => oldAssu.ASSU_AssuranceType.EST_Option == true
          ).find((oldAssu) => oldAssu.ID_TypeAssurance == assu);

          if (!exist) this.change.optionAssurances = true;
        });
      }
    },

    assuranceOptionIJChange(assurancesID) {
      if (this.licenceSaison && this.licenceSaison.UTI_LicenceAssurance) {
        // Set main assurande
        this.change.optionAssurancesIJ = false;

        //Check if we have a different assurance to toggle change prop
        let exist = this.licenceSaison.UTI_LicenceAssurance.filter(
          (oldAssu) =>
            oldAssu.ASSU_AssuranceType.EST_Option == true &&
            !oldAssu.ASSU_AssuranceType.EST_AssuranceComplementaire
        ).find((oldAssu) => oldAssu.ID_TypeAssurance == assurancesID);

        if (!exist) this.change.optionAssurancesIJ = true;
      }
    },

    async setData() {
      let ids = this.users.map((user) => {
        return user.ID_Utilisateur;
      });
      // pass an array of ids and save the licences for each user we passed into the itemSelected
      let response = await this.getLicenceUtilisateur({ ID_Utilisateurs: ids });
      if (response) {
        this.allLicenceStructures = response;
        this.allLicences = response.filter(
          (lic) => lic.ID_Structure == this.users[0].ID_Structure && lic.EST_Actif
        );
      }

      await this.getGroupeCreneauFullData({
        ID_Structure: this.context.ID_Structure,
        ID_Saison: this.context.ID_Saison,
      });

      await this.setSaisons();
      await this.setFamilleTarif();
      await this.setLicenceType();
      await this.setAssurance();

      this.removeFormError();
      if (
        this.user &&
        this.saison == this.selectedSaison.id &&
        this.allLicenceStructures.find(
          (lic) => lic.ID_Saison == this.selectedSaison.id && lic.EST_Actif
        )
      ) {
        return this.hasLicenceOnNextStructure();
      }
      return;
    },

    close() {
      this.$emit("onClose");
    },
    getIfHasAdultAndJeune(users) {},

    openConfirmModal() {
      //this.formatData();
      let yeartoday = new Date().getFullYear();
      const userCannotTakeLicenceJeune = this.users.some((user) => {
        const birth = this.$moment(
          user.UTI_Utilisateur.DN_DateNaissance
        ).format("DD/MM/YYYY");

        const finDate = `31/08/${this.currentSaison.id}`;

        const realAge = this.$moment(finDate, "DD/MM/YYYY").diff(
          this.$moment(birth, "DD/MM/YYYY"),
          "years"
        );
        return realAge >= 18;
      });
      console.log(
        "userCannotTakeLicenceJeune :>> ",
        userCannotTakeLicenceJeune
      );
      const userCannotTakeLicenceAdult = this.users.some((user) => {
        const birth = this.$moment(
          user.UTI_Utilisateur.DN_DateNaissance
        ).format("DD/MM/YYYY");

        const finDate = `31/08/${this.currentSaison.id}`;

        const realAge = this.$moment(finDate, "DD/MM/YYYY").diff(
          this.$moment(birth, "DD/MM/YYYY"),
          "years"
        );
        return realAge < 18;
      });
      console.log(
        "userCannotTakeLicenceAdult :>> ",
        userCannotTakeLicenceAdult
      );
      if (this.licence === 1 && userCannotTakeLicenceJeune) {
        return error_notification(
          "Type de licence jeune mais il semble y avoir des utilisateurs de plus de 18ans"
        );
      }
      if (this.licence === 2 && userCannotTakeLicenceAdult) {
        return error_notification(
          "Type de licence adulte mais il semble y avoir des utilisateurs de moins de 18ans"
        );
      }
      this.modalConfirmTarifIsOpen = true;
    },

    assurancesListFiltered() {
      let list = this.assurancesList.filter(
        (assu) =>
          assu.EST_Option === false
      );

      // disabled if we can't downgrade
      if (this.licenceSaison) {
        let assurance = this.licenceSaison.UTI_LicenceAssurance.find(
          (assu) => assu.ASSU_AssuranceType.EST_Option == false
        ).ASSU_AssuranceType;
        // remove assu that cant be selected
        list = list.filter((assu) => assu.Ordre >= assurance.Ordre);
      } else {
        list.map((assu) => {
          assu.disabled = false;
          return assu;
        });
      }
      return list;
    },

    // Filter options assurance
    assurancesOptionListFiltered() {
      let list = [
        ...this.assurancesList.filter(
          (assu) =>
            assu.EST_Option === true && !assu.EST_AssuranceComplementaire
        ),
      ];

      // if we have a liicence, disable the one we can't remove and downgrade
      if (this.licenceSaison) {
        let assurance = this.licenceSaison.UTI_LicenceAssurance.filter(
          (assu) => assu.ASSU_AssuranceType.EST_Option
        );

        list.forEach((assuList) => {
          assuList.disabled = false;
          assurance.forEach((assuLicence) => {
            if (assuList.id == assuLicence.ID_TypeAssurance)
              assuList.disabled = true;

            if (
              assuList.EST_AssuranceComplementaire == true &&
              assuLicence.ASSU_AssuranceType.EST_AssuranceComplementaire ==
                true &&
              assuLicence.ASSU_AssuranceType.Ordre >= assuList.Ordre
            ) {
              assuList.disabled = true;
            }
          });
        });
      } else {
        list.map((assu) => {
          assu.disabled = false;
          return assu;
        });
      }
      // disabled options if RC
      if (this.assurance === 14) {
        list.map((assu) => {
          if (assu.id === 25) {
            assu.disabled = false;
          } else {
            assu.disabled = true;
          }
          return assu;
        });
      }
      return list;
    },

    assurancesOptionIJListFiltered() {
      let list = [
        ...this.assurancesList.filter(
          (assu) =>
            assu.EST_Option === true &&
            assu.EST_AssuranceComplementaire === true
        ),
      ];
      // disabled if we can't downgrade
      if (this.licenceSaison) {
        let assurance = null;
        let hasAssurance = this.licenceSaison.UTI_LicenceAssurance.find(
          (assu) =>
            assu.ASSU_AssuranceType.EST_Option == true &&
            assu.ASSU_AssuranceType.EST_AssuranceComplementaire == true
        );
        if (hasAssurance) {
          assurance = hasAssurance.ASSU_AssuranceType;
        }
        // remove assu that cant be selected
        if (assurance) {
          list = list.filter((assu) => assu.Ordre >= assurance.Ordre);
        }
      } else {
        list.map((assu) => {
          assu.disabled = false;
          return assu;
        });
      }

      list.map((l) => {
        l.NomTypeAssurance;
      });
      return list;
    },

    hasLicenceOnOtheStr() {
      this.hasGlobalError = true;
      this.globaleErrorMessage.push(
        "Cet utilisateur est licencié sur une autre structure pour cette saison"
      );
    },
    hasLicenceOnNextStructure() {
      this.hasGlobalError = true;
      let licence = this.allLicenceStructures.find(
        (lic) => lic.ID_Saison == this.saison && lic.EST_Actif
      );
      this.globaleErrorMessage.push(
        "Une licence est déjà définie sur la structure " +
          licence.STR_Structure.NomStructure +
          " pour cet utilisateur"
      );
    },

    removeFormError() {
      this.hasGlobalError = false;
      this.globaleErrorMessage = [];
    },

    async onSaisonSelected() {
      await this.setFamilleTarif();
      await this.setLicenceType();
      await this.setAssurance();
      this.removeFormError();

      if (
        !this.itemsSelected &&
        this.saison == this.currentSaison.id &&
        !this.user.EST_StructureLicencie
      ) {
        this.licence = null;
        this.assurance = null;
        this.optionAssurances = [];
        return this.hasLicenceOnOtheStr();
      } else if (
        this.saison == this.selectedSaison.id &&
        this.allLicenceStructures.find(
          (lic) => lic.ID_Saison == this.selectedSaison.id && lic.EST_Actif
        )
      ) {
        return this.hasLicenceOnNextStructure();
      }
      //this.formKey += 1
    },

    // async onTarifSelected(tarifID) {
    //   console.log("tarifID",tarifID)
    //   if (this.licenceSaison && this.licenceSaison.ID_Tarif) {
    //     // Set main assurande

    //     //Check if we have a different assurance to toggle change prop
    //     this.change.tarif = false;
    //     if (tarifID != this.licenceSaison.ID_Tarif) {
    //       this.change.tarif = true;
    //     }
    //   }
    // },

    // set assurance list and selected one for current licence
    async setAssurance() {
      this.assurancesList = await this.getListTypeAssurances();
      if (this.licenceSaison && this.licenceSaison.UTI_LicenceAssurance) {
        // Set main assurande
        let assurance = this.licenceSaison.UTI_LicenceAssurance.find(
          (assu) => assu.ASSU_AssuranceType.EST_Option == false
        );
        if (assurance) this.assurance = assurance.ASSU_AssuranceType.id;

        // Set option assurances
        this.optionAssurances = this.licenceSaison.UTI_LicenceAssurance.filter(
          (assu) =>
            assu.ASSU_AssuranceType.EST_Option == true &&
            !assu.ASSU_AssuranceType.EST_AssuranceComplementaire
        ).map((assu) => assu.ASSU_AssuranceType.id);

        // Set option assurance IJ
        let assuranceIJ = this.licenceSaison.UTI_LicenceAssurance.find(
          (assu) =>
            assu.ASSU_AssuranceType.EST_Option == true &&
            assu.ASSU_AssuranceType.EST_AssuranceComplementaire
        );
        if (assuranceIJ) {
          this.optionAssurancesIJ = assuranceIJ.ASSU_AssuranceType.id;
        }
      }
    },

    // Set the saison available and selected  by default
    async setSaisons() {
      this.saisonsList = [this.currentSaison];
      if (this.selectedSaison.id && this.selectedSaison.preOpen) {
        this.saisonsList.push(this.selectedSaison);
      }
      this.saison = this.saisonsList.find(
        (s) => s.id == this.context.ID_Saison
      )?.id;
      return this.saisonsList;
    },

    // Get/Set the famille tarif licence
    async setFamilleTarif() {
      let tarifsFamilleList = await this.getAffiliationTarifStructure(
        this.context
      );
      tarifsFamilleList = tarifsFamilleList.filter(
        (tarif) => tarif.ID_Saison == this.saison
      );
      // If we don't have a tarif for this saisons, send an error message
      if (!tarifsFamilleList && !tarifsFamilleList.length) {
        this.hasGlobalError = true;
        this.globaleErrorMessage.push(
          "Aucuns tarifs défini par le club disponible pour cette saison"
        );
      }
      // Check if we have a tlicence with tarif for the saison selected
      await sleep(500);
      if (this.licenceSaison && tarifsFamilleList && tarifsFamilleList.length) {
        if (tarifsFamilleList) {
          //this.tarif = tarifsFamilleList.find(tarif => tarif.id == this.licenceSaison.ID_Tarif).id
          tarifsFamilleList.forEach((tarif) => {
            tarif.disabled = false;
            if (tarif.id != this.licenceSaison.ID_Tarif) {
              this.tarif = tarif.id;
              tarif.disabled = true;
            }
          });
        }
      }
      this.tarifsFamilleList = tarifsFamilleList;
      return;
    },

    // Set the different type of licence available
    async setLicenceType() {
      let licenceType = await this.getListTypeLicence();
      // Selon l'âge de l'utilisateur, on afficche un certain type de licence

      const fetch = require('node-fetch');

      const response = await fetch(
        `${process.env.VUE_APP_FFME_BACK_URL}/api/users/${this.users[0].UTI_Utilisateur.id}/family-members?structureId=${this.context.ID_Structure}`,
        {
          method: 'GET',
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
            'Content-Type': 'application/json',
          },
        }
      );

      const json = await response.json();

      if (!json.success) {
        error_notification("Une erreur est survenue lors de la récupération des membres de la famille.");
        return;
      }

      let nbFamilyLicences = Object.values(json.members).filter(
        (idTypeLicence) => idTypeLicence === 3
      ).length;

      if (!this.itemsSelected) {
        const birth = this.$moment(
          this.user.UTI_Utilisateur.DN_DateNaissance
        ).format("DD/MM/YYYY");

        const finDate = `31/08/${this.currentSaison.id}`;

        const realAge = this.$moment(finDate, "DD/MM/YYYY").diff(
          this.$moment(birth, "DD/MM/YYYY"),
          "years"
        );

        if ((Object.keys(json.members).length - nbFamilyLicences) >= 2) {
          if (realAge >= 18) {
          this.licenceTypeList = licenceType.filter(
            (licence) =>
              licence.SlugTypeLicence == "adulte" || licence.SlugTypeLicence == "famille"
          );
          } else {
            this.licenceTypeList = licenceType.filter(
              (licence) =>
                licence.SlugTypeLicence == "jeune" || licence.SlugTypeLicence == "famille"
            );
          }
        } else {
          if (realAge >= 18) {
          this.licenceTypeList = licenceType.filter(
            (licence) =>
              licence.SlugTypeLicence == "adulte"
          );
          } else {
            this.licenceTypeList = licenceType.filter(
              (licence) =>
                licence.SlugTypeLicence == "jeune"
            );
          }
        }

      } else {
        this.licenceTypeList = licenceType.filter((lic) => !lic.EST_HorsClub);
      }

      if (this.licenceSaison && this.licenceSaison.EST_Actif) {
        const licenceType = this.licenceTypeList.find(
          (lic) => lic.id == this.licenceSaison.ID_TypeLicence
        );
        if (licenceType) {
          this.licence = licenceType.id;
        }
        this.licenceTypeList.map((lic) => {
          if (lic.id != this.licenceSaison.ID_TypeLicence) {
            lic.disabled = true;
          }
          return lic;
        });
      } else {
        this.licenceTypeList.map((licence) => {
          licence.disabled = false;
          return licence;
        });
      }

      // Check if the licence is non pratiquant
      if (this.licenceSaison && this.licenceSaison.isNonPratiquant) {
        this.oldIsNonPratiquant = true;
        this.isNonPratiquant = true;
      }

      // Remove licence type Famille when in multiple licence renew case
      if (this.licenceCount && this.licenceCount > 0) {
        this.licenceTypeList = this.licenceTypeList.filter((licence) => !licence.SlugTypeLicence.includes("famille"));
      }

      return this.licenceTypeList;
    },

    getLicenceSaisonUser(user) {
      return this.allLicences.find(
        (lic) =>
          lic.ID_Utilisateur == user.ID_Utilisateur &&
          lic.ID_Saison == this.saison && lic.EST_Actif
      );
    },

    async submit(confirmResponse) {
      this.loading = true;
      let IDSToRemove = [];
      const fetch = require("node-fetch");

      let usersData = this.users.map((user) => {
        //Get the licence for the current user
        let licenceUserSaison = this.getLicenceSaisonUser(user);
        let payload = {};

        // set the assurances array
        let idsAssurances = [];
        if (this.assurance)
          idsAssurances = [...idsAssurances, ...[this.assurance]];
        if (this.optionAssurances.length)
          idsAssurances = [...idsAssurances, ...this.optionAssurances];
        if (this.optionAssurancesIJ)
          idsAssurances = [...idsAssurances, ...[this.optionAssurancesIJ]];

        let assurances = idsAssurances.map((assu) => {
          let ok = confirmResponse.tarifs.find(
            (tarif) => tarif?.ID_TypeAssurance == assu
          );

          let item = {
            ID_Saison: this.saison,
            ID_Utilisateur: user.ID_Utilisateur,
            ID_Tarif: confirmResponse.tarifs.find(
              (tarif) => tarif?.LIST_TarifCategorie?.ID_TypeAssurance == assu
            )
              ? confirmResponse.tarifs.find(
                  (tarif) =>
                    tarif?.LIST_TarifCategorie?.ID_TypeAssurance == assu
                ).id
              : null,
            ID_TypeAssurance: assu,
          };

          if (licenceUserSaison) {
            // check if we have already the same assurance
            licenceUserSaison.UTI_LicenceAssurance.map((assuLicence) => {
              // Si j'ai la même assurance, je repasse simplement l'id
              if (assuLicence.ID_TypeAssurance == assu)
                item.id = assuLicence.id;
            });
          }
          return item;
        });

        // Check for the diff, maybe we need to delete some entries now
        let selectedAssurancesId = assurances.map((a) => {
          return a.ID_TypeAssurance;
        });
        console.log("licenceUserSaison :>> ", licenceUserSaison);
        if (licenceUserSaison) {
          // If wealready  have licence , we get the difference of assurances id for removing the old one
          IDSToRemove = licenceUserSaison.UTI_LicenceAssurance.filter(
            (licAssu) =>
              !selectedAssurancesId.includes(licAssu.ID_TypeAssurance)
          ).map((la) => {
            return la.id;
          });

          // pass the ifd of the old licence to update
          payload.id = licenceUserSaison.id;
        }
        let selectedSaisonEnd = this.saisonsList.find(
          (s) => s.id === this.saison
        ).dateEnd;
        // set globals variables mutation
        let variables = {
          ID_Saison: this.saison,
          ID_Structure: this.currentStructureId,
          ID_TypeLicence: this.licence,
          ID_Utilisateur: user.ID_Utilisateur,
          ID_Tarif: this.tarif,
          NomGroupe: null,
          OptionAdhesion: JSON.stringify(this.OptionsAdhesions),
          ID_EtapeLicence: 1,
          DateLicenceValidation: this.$moment(),
          DateLicenceFinValidite: this.$moment.utc(selectedSaisonEnd),
          isNonPratiquant: this.isNonPratiquant,
          created_by_structure: this.currentStructureId,
          created_by_user: this.currentUserId,
          creation_type: this.$route.name === 'gestion_des_licences__renouveler_licences_en_serie' ? 'RENOUVELLEMENT' : 'MANUEL',
          status: validate,
          UTI_LicenceAssurance: {
            data: assurances,
            on_conflict: {
              constraint: "UTI_LicenceAssurance_pkey",
              update_columns: [
                "DateSouscriptionAssurance",
                "ID_Tarif",
                "ID_Saison",
              ],
            },
          },
        };
        return { ...payload, ...variables };
      });

      try {
        const response = await this.updateLicenceUtilisateurStructureList({
          ID_Structure: this.currentStructureId,
          items: usersData,
          rmID_UTI_LicenceAssurance: IDSToRemove,
          estLicencie: true,
        });
        let userUpdate = {
          ID_Utilisateur: this.users[0].UTI_Utilisateur.id,
          ID_LastLicence: response[0].id,
          ID_Structure: this.currentStructureId,
          EST_StructureLicencie: true,
          EST_Actif: true,
          id: this.users[0].id,
        }
        if (this.users[0].ID_Role) {
          userUpdate.ID_Role = this.users[0].ID_Role;
        }
        if (this.users[0].ID_Fonction) {
          userUpdate.ID_Fonction = this.users[0].ID_Fonction;
        }
        if (this.users[0].EST_RoleFederal) {
          userUpdate.EST_RoleFederal = this.users[0].EST_RoleFederal;
        }
        if (this.users[0]?.ID_Structure !== this.currentStructureId) {
          delete userUpdate.id;
        }
        await this.addUserToStructureOnPrimoAffi({
          user: [{ ...userUpdate }],
        });

        response.map(async (res) => {
          let filtre = res.UTI_LicenceAssurance.filter(
            (licence) =>
              licence.DateSouscriptionAssurance !== null &&
              licence.ID_Tarif == null
          );

          if (filtre.length) {
            await this.deleteLicenceAssurance({ id: filtre[0]?.id });
          }
        });

        if (response) {
          let usersToUpLastLicence = [];
          this.users.forEach(async (user) => {
            if (
              !user.UTI_Licence ||
              (user.UTI_Licence && user.UTI_Licence.ID_Saison < this.saison)
            ) {
              delete user.UTI_Licence;
              delete user.UTI_Utilisateur;
              delete user.STR_Structure;
              delete user.ADM_RoleType;
              delete user.LIST_MissionSalarie;
              delete user.__typename;
              response.forEach((respo) => {
                if (user.ID_Utilisateur == respo.ID_Utilisateur) {
                  user.EST_Actif = true;
                  user.ID_LastLicence = respo.id;
                }
              });

              usersToUpLastLicence.push(user);
            }
          });

          // envoyer mail d'activation compte
          // Z_DatePremiereConnexion dans UTI_Utilisateurs est NULL
          // Z_DateDerniereConnexion dans UTI_Utilisateurs est < 01/06/2022

          //SENDEMAIL addLicenceToUser
          try {
            const emailResponse = await this.sendEmail();
            console.log("emailResponse", emailResponse);
          } catch (e) {
            console.log("error", e);
          }
          console.log("this.users[] :>> ", this.users[0]);
          Promise.all([
            await this.updateLastLicenceUtilisateur({
              data: usersToUpLastLicence,
            }),
            await this.updateUserESTLicencie({
              ID_Utilisateur: this.users[0].ID_Utilisateur,
            }),
          ]);
        }
        // NEW FLUX BACK FFME
        if (response && response.length > 0) {
          response.forEach(async (user) => {
            await insertFlux(formatFluxFromLicenceModal(user, 'achat'));
          })
        }

        await this.createFluxFinancier(confirmResponse);
        this.loading = false;
        success_notification("Licence et assurances mises à jour!");
        this.modalConfirmTarifIsOpen = false;
        bus.$emit("onUserListStructureUpdated");
        this.$emit("onUpdate", this.users[0]);
        this.close();
      } catch (e) {
        console.log(e);
        error_notification("Oups, erreur");
      }
    },

    async sendEmail() {
      this.users.forEach(async (user) => {
        // const fullDataUser = await this.getUtilisateur(user.ID_Utilisateur);
        console.log("this.users[0]", this.users);
        console.log("user map", user);

        let payload = {
          User: user.ID_Utilisateur, // l'utitlisateur cible
          Structure: this.currentStructure.STR_Structure.NomStructure, // la str cible
          // Structure: this.context.user.STR_Structure.NomStructure, // la str cible
          Email: user.UTI_Utilisateur?.CT_Email,
          // ID_Structure: this.currentStructureId.toString(),
          Saison: this.saison.toString(),
          Context: "addLicenceToUser",
          SlugModule: "gestion_des_licences__saisir_une_licence",
        };
        //NOTIFICATION "Délivrance licence (notice d'assurance)"
        await send_email(payload);
      });
    },

    async createFluxFinancier(confirmResponse) {
      confirmResponse.fluxData.map(async (flux) => {
        if (confirmResponse.flux?.id) {
          await this.deleteFluxFinancierDetail({
            ID_FluxFinancier: confirmResponse.flux.id,
          });
        }
        let fluxdetails = flux.MKP_FluxFinancierDetail?.data?.filter(
          (element) => element.ID_Tarif != undefined
        );

        let totalAmount = 0;
        fluxdetails.map((element) => (totalAmount += element.MontantHT));

        flux.MKP_FluxFinancierDetail.data = fluxdetails;
        flux.MontantHT = totalAmount;
        flux.MontantTTC = totalAmount;
        const insertedFlux = await this.createFluxFinancierDetail({
          flux: flux,
        });
      });
      //remove pirnt facture for create licencie
      // this.printFacture(insertedFlux);
      return;
    },

    isNonPratiquantHasChange(value) {
      console.log(value);
    },

    printFacture(item) {
      this.$refs.facture.printFacture(item);
    },
  },

  props: {
    modalIsOpen: {
      type: Boolean,
      default: false,
    },

    user: {
      type: Object,
    },

    context: {
      type: Object,
    },

    itemsSelected: {
      type: Array,
    },

    renewLicence: {
      type: Boolean,
      default: false,
    },
    licenceCount: {
      type: Number,
    },
  },
};
</script>

<style scoped>
.theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-list-item--disabled {
  /* color: rgba(0, 0, 0, 0.87); */
}
.checkbox-pratiquant .v-input--selection-controls {
  margin-top: 0;
}
.non-pratiquant-warning {
  font-size: 0.7em;
}
.close-icon {
  margin-top: 10px !important;
}
</style>
