<template>
  <v-col cols="12 py-0">
    <v-row v-if="mounted">
      <v-col
        cols="12"
        md="7"
        class="py-0"
        style="display: flex; align-items: center;"
      >
        <div class="px-2 py-2">
          <span class="font-weight-bold" style="font-size: 0.9rem;">
            {{ documentType ? documentType.NomTypeDocument : '' }}
            {{ isAffiliation && (documentType.SlugTypeDocument === 'compte_rendu_ag'
                || documentType.SlugTypeDocument === 'diplome_encadrant'
                || documentType.SlugTypeDocument === 'copie_cartes_professionnelles'
                || documentType.SlugTypeDocument === 'attestation_assurance') ? '*' : ''
            }}
          </span>
        </div>

        <div v-if="isAffiliation && documentType.SlugTypeDocument === 'reglement_interieur' || isAffiliation && documentType.SlugTypeDocument === 'status'" style="font-size: 12px; font-weight: 300;">
          A transmettre en cas de mise à jour depuis la saison dernière
        </div>
      </v-col>
      <v-col cols="12" md="5" class="text-right">
        <span v-if="document.Z_DateCreation" style="font-size: 13px;">
          {{
            $moment(document.Z_DateCreation).format("DD/MM/YYYY")
          }}</span
        >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="ml-2"
              :disabled="!hasCodeFederal"
              @click="openPreview"
              fab
              dark
              x-small
              outlined
              :color="hasDocument ? 'indigo darken-4' : 'grey lighten-1'"
            >
              <v-icon>mdi-image</v-icon>
              <modalPreviewDocument
                @onClose="modalPreviewIsOpen = false"
                :isOpen="modalPreviewIsOpen"
                v-if="modalPreviewIsOpen"
                :context="{
                  ...context,
                  document: document,
                  documentType: documentType,
                }"
              ></modalPreviewDocument>
            </v-btn>
          </template>
          <span>Afficher le document</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="ml-2 mr-2"
              :disabled="!hasCodeFederal"
              @click.prevent="documents && documents.length !== 0 ? modalHistoryIsOpen = true : null"
              fab
              dark
              x-small
              outlined
              :color="documents && documents.length !== 0 ? 'indigo darken-4' : 'grey lighten-1'"
            >
              <v-icon>mdi-history</v-icon>
              <modalHistoryDocument
                @onClose="modalHistoryIsOpen = false"
                :isOpen="modalHistoryIsOpen"
                v-if="modalHistoryIsOpen"
                :context="{
                  ...context,
                  document: document,
                  documentType: documentType,
                }"
              ></modalHistoryDocument>
            </v-btn>
          </template>
          <span>Afficher l'historique</span>
        </v-tooltip>

        <v-tooltip top v-if="!isDisabled">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :disabled="!hasCodeFederal"
              @click="openModalUpload"
              fab
              dark
              outlined
              x-small
              color="indigo darken-4"
            >
              <v-icon dark>mdi-plus</v-icon>
              <template v-if="isNewDocType">
                <modalUploadNewDocument
                  v-if="modalUploadDocumentIsOpen"
                  @onClose="modalUploadDocumentIsOpen = false"
                  @insertDocumentNewItem="insertFileInDB"
                  :isOpen="modalUploadDocumentIsOpen"
                  :dateneeded="false"
                  :context="{
                    ...context,
                    document: document,
                    documentType: documentType,
                  }"
                ></modalUploadNewDocument>
              </template>

              <template v-else>
                <modalUploadDocument
                  v-if="modalUploadDocumentIsOpen"
                  @onClose="modalUploadDocumentIsOpen = false"
                  :isOpen="modalUploadDocumentIsOpen"
                  :dateneeded="false"
                  :context="{
                    ...context,
                    document: document,
                    documentType: documentType,
                  }"
                  @onFileUploaded="fileUploaded"
                ></modalUploadDocument>
              </template>
            </v-btn>
          </template>
          <span>Téléverser un nouveau document</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import modalHistoryDocument from "@/components/utils/modals/Documents/Document__HistoryDocumentModal.vue";
import modalUploadDocument from "@/components/utils/modals/Documents/Document__UploadDocument.vue";
import modalUploadNewDocument from "@/components/utils/modals/Documents/Document__UploadNewDocument.vue";
import modalPreviewDocument from "@/components/utils/modals/Documents/Document__PreviewDocument.vue";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";

export default {
  components: {
    modalHistoryDocument,
    modalUploadDocument,
    modalUploadNewDocument,
    modalPreviewDocument,
  },

  data: () => ({
    allDocumentType: [],
    modalHistoryIsOpen: false,
    modalUploadDocumentIsOpen: false,
    modalPreviewIsOpen: false,
    mounted: false,
    documents: [],
  }),

  async created() {
    await this.setData();

    if (this.documentType) {
      this.documents = await this.getDocumentStructureByType({
        ID_Structure: this.context.ID_Structure,
        ID_DocumentType: this.documentType.id,
      });
    }

    this.mounted = true;
  },

  computed: {
    ...mapGetters("structure", ["hasCodeFederal"]),
    DocumentTitle() {
      return this.document.NomDocument;
    },

    documentType() {
      console.log("this.allDocumentType :>> ", this.allDocumentType);
      console.log(
        "this.document.ID_Type_Document :>> ",
        this.document.ID_Type_Document
      );
      console.log("this.document :>> ", this.document);
      let cat = this.allDocumentType.find(
        (doc) => doc.id == this.document.ID_Type_Document
      );
      if (cat) return cat;
      return null;
    },

    hasDocument() {
      return this.document.CheminDocument && this.document.NomDocument;
    },

    isNewDocType() {
      return this.$listeners.insertDocument;
    },
  },

  methods: {
    ...mapActions("basics", ["getListTypesDocument", "insertDocument"]),
    ...mapActions("structure", [
      "updateAffiliationDocument",
      "getDocumentStructureByType",
    ]),

    async setData() {
      this.allDocumentType = await this.getListTypesDocument();
    },

    openPreview() {
      if (!this.hasDocument) return false;
      return (this.modalPreviewIsOpen = true);
    },

    openModalUpload() {
      this.modalUploadDocumentIsOpen = (true && !this.isDisabled);
    },

    async fileUploaded(file) {
      this.modalUploadDocumentIsOpen = false;
      let document = await this.insertFileInDB(file);

      try {
        let payload = {
          ID_Structure: this.context.ID_Structure,
          ID_Saison: this.context.ID_Saison,
          ID_Doc: document.id,
          GQLType: this.documentType.SlugTypeDocument,
        };
        await this.updateAffiliationDocument(payload);
        this.$emit("onUploaded");
        success_notification("Ficher enregistré");
      } catch (e) {
        console.log("error :>> ", e);
        error_notification("Oups, une erreur st survenue");
      }
    },

    async insertFileInDB(file) {
      if (this.isNewDocType) {
        this.$emit("insertDocument", file);

        return;
      }

      let payload = {
        CheminDocument: file.url,
        NomDocument: file.filename,
        DocumentType: this.documentType,
        ID_Structure: this.context.ID_Structure,
        DateValidation: file.date,
      };
      try {
        let document = await this.insertDocument(payload);
        return document;
      } catch (e) {
        error_notification(
          "Oups, une erreur est survenue lors du téléchargement du document..."
        );
      }
    },

    openHistoryModal() {
      this.modalHistoryIsOpen = true;
    },
  },

  props: {
    document: {
      type: Object,
    },
    context: {
      type: Object,
    },
    isAffiliation: {
      type: Boolean,
      default: false,
    },
    ID_DocCompteRenduAg: {
      type: Number,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
hr {
  margin: 0;
  padding: 0;
}
</style>
