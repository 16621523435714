import { getSeasons } from "../../plugins/seasonService";

export default {
  namespaced: true,

  state: {
    saisons: [],
    selectedSaison: null,
  },

  getters: {
    saisons: (state) => state.saisons,

    currentSaison(state) {
      return state.saisons.find((saison) => saison.active == true);
    },

    currentSaisonId(state, getters) {
      return getters.currentSaison.id;
    },

    selectedSaison: (state) => {
      return state.selectedSaison;
    },

    selectedSaisonId(state, getters) {
      return getters.selectedSaison.id;
    },

    lastSaison(state, getters) {
      return state.saisons.find(
        (saison) => saison.id == getters.selectedSaison - 1
      );
    },

    lastSaisonId(state, getters) {
      return getters.lastSaison.id;
    },

    getSaison: (state) => (saison_annee) => {
      return state.saisons.find((saison) => saison.id == saison_annee);
    },

    nextSaison(state, getters) {
      return state.saisons.filter((season) => season.next).slice(-1)[0];
    },
  },

  mutations: {
    SET_SAISONS(state, payload) {
      state.saisons = payload;
    },

    SET_SELECTED_SAISON(state, payload) {
      state.selectedSaison = payload;
    },
  },

  actions: {
    async initSaisons({ dispatch, commit, getters }, payload) {
      await commit("SET_SAISONS", payload);

      let saison = getters.currentSaison;

      if (getters.nextSaison) {
        saison = getters.nextSaison;
      }

      await commit("SET_SELECTED_SAISON", saison);

      return;
    },

    async getSaisons() {
      return await getSeasons({
        'pagination': false,
        'order[id]': 'desc',
      });
    },

    async setSelectedSaison({ commit, getters, dispatch }, payload) {
      await dispatch("app/setAppIsLoading", true, { root: true });

      await commit(
        "SET_SELECTED_SAISON",
        getters.saisons.find((sais) => sais.id == payload.ID_Saison)
      );
      await dispatch("app/hydrateApp", {}, { root: true });

      await dispatch("app/setAppIsLoading", false, { root: true });
    },
  },
};
