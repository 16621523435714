<template>
    <v-col v-if="mounted">
        <div class="px-4 py-3">
            <h5>Tarifs FFME {{ SaisonName }}</h5>
            <div v-if="tarifsFFME.length" >

                <div>
                    <span class="font-weight-bold">Licences:</span> Jeune: {{tarifs.licence_jeune}}€ / Adulte: {{tarifs.licence_adulte}}€ / Famille: {{tarifs.licence_famille}}€
                    <!-- / Hors club jeune: {{tarifs.hors_club_jeune}}€ / Hors club adulte: {{tarifs.hors_club_adulte}}€  -->
                </div>
                <div>
                    <span class="font-weight-bold">Assurances:</span> Base: {{tarifs.assurance_base}}€ / Base+: {{tarifs['assurance_base+']}}€  / Base++: {{tarifs['assurance_base++']}}€ / RC: {{tarifs['assurance_rc']}}€
                </div>
                <div>
                    <span class="font-weight-bold">Options d'assurance:</span> Ski de piste:  {{tarifs.option_assurance_ski_de_piste}}€ / Trail: {{tarifs.option_assurance_trail}}€ / Slackline et highline: {{tarifs.option_assurance_slackline}}€ / VTT : {{tarifs.option_assurance_vtt}}€
                </div>
                <div>
                    <span class="font-weight-bold">Indemnités journalières:</span> IJ1:  {{tarifs.assurance_ij_1}}€ / IJ2: {{tarifs.assurance_ij_2}}€ / IJ3: {{tarifs.assurance_ij_3}}€
                </div>
                <!-- <div>
                    <span class="font-weight-bold">Cotisation affiliation club :</span>  {{tarifs.affiliation_club}}€
                </div> -->
                <div>
                    <span class="font-weight-bold">Licence découverte (€/jour) :</span>  {{tarifs.licence_decouverte}}€
                </div>
            </div>

            <div v-else>
                <span class="font-weight-bold">Aucuns tarifs renseignés</span>
            </div>
        </div>
    </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {

    data: () => ({
        mounted: false,
        tarifsFFMECategorie: [],
        tarifsFFME: [],

        tarifs: {},

        loading: false
    }),

    async created() {
        await this.setData();
        this.mounted = true;
    },

    computed: {
        ...mapGetters("saisons", ['currentSaison', "selectedSaison"]),

        SaisonName() {
            return this.selectedSaison.label;
        },
    },

    methods : {
        ...mapActions("basics", ['getTarifsCategorieFFME', 'getTarifsFFME', 'insertTarifs']),

        async setData() {
            // get the categorie tarif
            this.tarifsFFMECategorie = await this.getTarifsCategorieFFME()
            console.log('this.tarifsFFMECategorie:', this.tarifsFFMECategorie)
            // get the actual tarif for this saison
            let saisonID = this.context.ID_Saison ? this.context.ID_Saison : this.currentSaison.id
            console.log('saisonID:', saisonID)
            this.tarifsFFME = await this.getTarifsFFME({ID_Saison: saisonID})
            console.log('this.tarifsFFME:', this.tarifsFFME)
            // set the model
            this.setTarifs(this.tarifsFFME)

            return true
        },

        // get tarfif by slug
        getTarif(slug) {
            let tarif =  null
            tarif = this.tarifsFFME.find(tarif => tarif.LIST_TarifCategorie.SlugCategorieTarif == slug)
            if(tarif) return tarif
            return null
        },

        // get a montant tarif by slug
        getTarifMontant(slug) {
            let tarif = this.getTarif(slug)
            if(tarif) return tarif.MontantUnitaire
            return null
        },

        // Set the tarif for the model
        setTarifs () {
            this.tarifs.affiliation_club = this.getTarifMontant('affiliation_club')
            this.tarifs.licence_decouverte = this.getTarifMontant('licence_decouverte')

            this.tarifs.licence_jeune = this.getTarifMontant('licence_jeune')
            this.tarifs.licence_adulte = this.getTarifMontant('licence_adulte')
            this.tarifs.licence_famille = this.getTarifMontant('licence_famille')

            this.tarifs.assurance_rc = this.getTarifMontant('assurance_rc')
            this.tarifs.assurance_base = this.getTarifMontant('assurance_base')
            this.tarifs['assurance_base+'] = this.getTarifMontant('assurance_base+')
            this.tarifs['assurance_base++'] = this.getTarifMontant('assurance_base++')


            this.tarifs.option_assurance_ski_de_piste = this.getTarifMontant('option_assurance_ski_de_piste')
            this.tarifs.option_assurance_trail = this.getTarifMontant('option_assurance_trail')
            this.tarifs.option_assurance_slackline = this.getTarifMontant('option_assurance_slackline')
            this.tarifs.option_assurance_vtt = this.getTarifMontant('option_assurance_vtt')


            this.tarifs.assurance_ij_1 = this.getTarifMontant('assurance_ij_1')
            this.tarifs.assurance_ij_2 = this.getTarifMontant('assurance_ij_2')
            this.tarifs.assurance_ij_3 = this.getTarifMontant('assurance_ij_3')

            this.tarifs.hors_club_jeune = this.getTarifMontant('hors_club_jeune')
            this.tarifs.hors_club_adulte = this.getTarifMontant('hors_club_adulte')
        },
    },

    props: {
        context: {
            type: Object,
            default: () => {}
        }
    }



}

</script>
