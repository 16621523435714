import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
// Components
import { getToken } from "@/plugins/auth";
import Licence from "../views/Licence.vue";
import HorsClubRoutes from "@/router/routes/HorsClub";
import PrimoAffilicationRoutes from "@/router/routes/PrimoAffilication";

// Custom routes
import RoutesMonCompte from "./MonCompte";
import RoutesMesLicences from "@/router/routes/MesLicences";
import RoutesGestionDeStructure from "@/router/routes/GestionDeStructure";
import RoutesGestionDesStructures from "@/router/routes/GestionDesStructures";
import RoutesGestionDesLicences from "@/router/routes/GestionDesLicences";
import RoutesGestionDesAffiliations from "@/router/routes/GestionDesAffiliations";
import RoutesGestionDesUtilisateurs from "@/router/routes/GestionDesUtilisateurs";
import RoutesGestionDesSaisons from "@/router/routes/GestionDesSaisons";
import RoutesAppSettings from "@/router/routes/AppSettings";
import RoutesMesAssurances from "@/router/routes/MesAssurances";
import RoutesGestionDesCompetitions from "@/router/routes/GestionDesCompetitions";
import RoutesGestionDesFinances from "@/router/routes/GestionDesFinances";
import RoutesGestionComptabilite from "@/router/routes/GestionComptabilite";
import LicencesDematerialiseRoutes from "@/router/routes/LicencesDematerialise";
import RoutesLemonwayRedirection from "@/router/routes/LemonwayRedirection";
import RoutesGestionParametresGlobaux from "@/router/routes/GestionDesParametresGlobaux";
// import RoutesPageEnConstruction from "@/router/routes/PageEnConstruction"

import { isEmpty } from "@/plugins/utils";
import { error_notification } from "@/plugins/notifications";
import { getStructureIdFromUrl } from "../plugins/getStructureIdFromUrl";
import { PRODUCT_TYPE_LICENCE_HC, getTransactions } from "../plugins/transactionService";

Vue.use(VueRouter);
// const CURRENT_STRUCTURE_ID =  store.getters['structure/currentStructureId']
const routes = [
  {
    path: "/",

    component: () => import("../views/layouts/Dashboard.vue"),

    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("../views/Accueil/Dashboard.vue"),
      },
      {
        path: "deconnexion",
        name: "Logout",
        beforeEnter: (to, from, next) => {
          localStorage.removeItem(process.env.VUE_APP_AUTH_TOKEN_NAME);
          store.dispatch("config/resetNotificationAffiliation");
          store.dispatch("config/resetNotificationLicenceDocument");
          return window.location = process.env.VUE_APP_FRONT_NEXT_URL + "/authentification/deconnexion";
        },
      },
      {
        path: "contact",
        name: "contact",
        meta: {
          can: false,
          title: "Contact",
          meta: { personalMode: true },
        },
        component: () => import("../views/Accueil/Contact.vue"),
      },

      {
        path: "mon-compte",
        component: () => import("../views/layouts/MonCompte.vue"),
        children: RoutesMonCompte,
      },

      {
        path: "mes-licences",
        component: () => import("../views/layouts/default.vue"),
        children: RoutesMesLicences,
      },

      {
        path: "mes-assurances",
        component: () => import("../views/layouts/default.vue"),
        children: RoutesMesAssurances,
      },

      {
        path: "gestion-comptabilite",
        component: () => import("../views/layouts/default.vue"),
        children: RoutesGestionComptabilite,
      },

      {
        path: "gestion-des-licences",
        component: () => import("../views/layouts/default.vue"),
        children: RoutesGestionDesLicences,
      },
      {
        path: "structures",
        component: () => import("../views/layouts/default.vue"),
        children: RoutesGestionDesStructures,
      },
      {
        path: "gestion-des-affiliations",
        component: () => import("../views/layouts/default.vue"),
        children: RoutesGestionDesAffiliations,
      },

      {
        path: "gestion-des-adherents",
        component: () => import("../views/layouts/default.vue"),
        children: RoutesGestionDesUtilisateurs,
      },

      {
        path: "gestion-des-competitions",
        component: () => import("../views/layouts/default.vue"),
        children: RoutesGestionDesCompetitions,
      },

      {
        path: "gestion-des-finances",
        component: () => import("../views/layouts/default.vue"),
        children: RoutesGestionDesFinances,
      },

      {
        path: "gestion-structure",
        component: () => import("../views/layouts/default.vue"),
        children: RoutesGestionDeStructure,
      },

      {
        path: "settings",
        component: () => import("../views/layouts/default.vue"),
        children: RoutesAppSettings,
      },
      {
        path: "parametres-globaux",
        component: () => import("../views/layouts/default.vue"),
        children: RoutesGestionParametresGlobaux,
      },

      {
        path: "premiere-connexion",
        name: "premiere_connexion",
        meta: {
          can: false,
          title: "Premiére connexion",
          meta: { personalMode: true },
        },
        component: () =>
          import(
            /* webpackChunkName: "MonCompte" */ "../views/PremiereConnexion.vue"
          ),
        beforeEnter: (to, from, next) => {
          if (store.getters["user/currentUser"].Z_DatePremiereConnexion) {
            return router.push({ name: "Home" });
          }
          return next();
        },
      },

      {
        path: "gestion-des-saisons",
        component: () => import("../views/layouts/GestionDesSaisons.vue"),
        children: RoutesGestionDesSaisons,
      },

      {
        path: "legal/:type",
        name: "legal",
        meta: {
          can: false,
          title: "Informations légales",
        },
        component: () => import("../views/Legal.vue"),
      },
    ],
  },
  {
    path: "/licences-hors-club",
    name: "Licence_Hors_Club",
    component: () => import("../views/layouts/public.vue"),
    children: HorsClubRoutes,
  },
  {
    path: "/licences-dematerialise",
    name: "Licences_Dematerialise",
    component: () => import("../views/layouts/public.vue"),
    children: LicencesDematerialiseRoutes,
  },
  {
    path: "/primo-affiliation",
    name: "primo_affiliation",
    component: () => import("../views/layouts/public.vue"),
    children: PrimoAffilicationRoutes,
  },
  {
    path: "/licence/:id",
    name: "Licence",
    component: Licence,
    meta: {
      public: true,
      can: false,
      title: "Licence",
    },
  },
  {
    path: "/lemonway",
    name: "Lemonway_redirection",
    component: () => import("../views/layouts/public-page-construction.vue"),
    children: RoutesLemonwayRedirection,
    meta: {
      public: true,
      can: false,
      title: "Lemonway",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkExactActiveClass: "__exact-active",
  linkActiveClass: "__active",
  routes,

  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 500);
    });
  },
});

router.beforeEach(async (to, from, next) => {
  let publicRoute = null;
  const widtlee = window.innerWidth;
  to.meta.personalMode
    ? localStorage.setItem("ffme_user_mode", "licencie")
    : localStorage.setItem("ffme_user_mode", "federal");

  if (to.meta && to.meta.public == true) {
    publicRoute = true;
  }

  if (!publicRoute) {
    //   store
    //     .dispatch("auth/attempt", getToken())
    //     .finally(async () => {
    //       await store.dispatch("app/initApp");

    // if( ! publicRoute) {

    store
      .dispatch("auth/attempt", { token: getToken(), to, from })
      .finally(async () => {
        await store.dispatch("app/initApp");

        store.dispatch('structure/setCurrentStructureFederal', getStructureIdFromUrl());
        // console.log("user", store.getters['user/currentUser'])

        if (store.getters["user/currentUser"].PrimoConnexion_Step !== 2) {
          return window.location = `${process.env.VUE_APP_FRONT_NEXT_URL}/primo-connexion?appMode=${localStorage.getItem("ffme_user_mode")}`;
        }

        let transactions = await getTransactions(getToken());
        transactions = transactions.data['hydra:member']

        if (transactions.length !== 0) {
          return window.location = `${process.env.VUE_APP_FRONT_NEXT_URL}/paiement/${transactions[0].id}?appMode=${localStorage.getItem("ffme_user_mode")}`;
        }

        // If param redirect in URL then redirect to Next.JS app
        if (to.query['ffme-referrer']) {
          let url = new URL(`${process.env.VUE_APP_FRONT_NEXT_URL}${to.query['ffme-referrer']}`);

          if (!url.searchParams.has('appMode')) {
            url.searchParams.append('appMode', localStorage.getItem("ffme_user_mode"));
          }

          return window.location = url.toString();
        }

        // If app as url in localstorage then redirect to this url
        if (localStorage.getItem('ffme-referrer')) {
          let lastVisitedPage = localStorage.getItem('ffme-referrer');
          localStorage.removeItem('ffme-referrer');
          return next(lastVisitedPage);
        }

        if (!isEmpty(to.meta)) {
          if (to.meta.can === true) {
            let can = store.getters["user/can"];
            if (can(to.name)) return next();
            error_notification("Vous ne pouvez pas accéder à ce module. ");
            return router.push({ name: "Home" });
          }
        }

        // return next();
        // check if mobile device
        // return widtlee < 600
        //   ? router.replace({ name: "page_en_construction" })
        //   : next();

        return next()
      })
      .catch((e) => e);
  } else {
    await await store.dispatch("app/initPublicApp");
    return next();
  }
});

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    // console.log(to.meta)
    let title = to.meta ? to.meta.title : "";
    // console.log(title)
    if (!title) return (document.title = "myFFME");
    return (document.title = "myFFME - " + title);
  });
});

export default router;
