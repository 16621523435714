<template>
  <div>
    <div v-if="isLoading" class="ma_licence__loading-container">
      <Loading :loading="true" />
    </div>
    <div v-else class="d-flex flex-column">
      <!-- <SubBar :title="subBarTitle" route_name="gestion_comptabilite__dashboard" /> -->

      <div class="d-flex flex-column align-start">
        <h5>{{ subBarTitle }}</h5>
      </div>
      <div v-if="isAdminClub" class="mt-5">
        <v-alert
          type="info"
          text
        >
          <p class="text-h6">Mettre à jour les informations</p>
          Pour changer vos coordonnées bancaires (prélèvements des licences), envoyez votre nouveau RIB ainsi qu'un mandat SEPA complété et signé (ajouter les documents en pièce jointe téléchargeable) à l'adresse <a href="mailto:licence@ffme.fr">licence@ffme.fr</a>.
        </v-alert>
      </div>
      <div class="d-flex flex-column align-start">
        <ValidateForm
          @validated="submit"
          :showFieldObl="false"
          class="file-container"
          v-if="!isAdminClub"
        >
          <div class="form-user-details__container mt-6">
            <h6 class="mb-0">Modalité de paiement :</h6>
            <!-- <v-select
              :items="prelevementstype"
              v-model="selectedType"
              item-value="id"
              item-text="Type"
              class="input-item"
              :disabled="isAdmin"
            ></v-select> -->
            <v-select
              :items="prelevementstype"
              v-model="selectedType"
              item-value="id"
              item-text="Type"
              class="input-item"
            ></v-select>
            <h6 class="mb-0">Date de changement du mode de paiement :</h6>
            <!-- <DatePicker
              :disabled="isAdmin"
              v-model="changedDate"
              rules="min:3"
            ></DatePicker> -->
            <DatePicker v-model="changedDate" rules="min:3"></DatePicker>
          </div>

          <!-- <div class="form-user-details__action">
            <v-btn type="submit" class="btn btn-primary mt-5" :disabled="isAdmin"
              >Mettre à jour</v-btn
            >
          </div> -->
          <div class="form-user-details__action">
            <v-btn type="submit" class="btn btn-primary mt-5"
              >Mettre à jour</v-btn
            >
          </div>
        </ValidateForm>
        <div class="file-container mt-5">
          <v-row>
            <DocumentType
              v-for="(item, index) in allDocuments"
              :key="index"
              :document="item"
              :context="{
                ID_Structure: structureID,
                ID_Saison: currentSaison.id,
              }"
              :isAffiliation="false"
              :documentUploaded="documentUploaded"
              :isDisabled="isAdminClub"
            />
          </v-row>


          <ValidateForm @validated="submitRib" :showFieldObl="false">
            <div class="form-user-details__container mt-6">
              <!-- <Textarea
                class="textareaField input-item mt-2"
                rules="required"
                v-model="domicilebancaire"
                twoLines
                label="Domiciliation bancaire"
                is-red-label
                :disabled="isAdmin"
              ></Textarea> -->
              <Textarea
                class="textareaField input-item mt-2"
                rules="required"
                v-model="domicilebancaire"
                twoLines
                label="Domiciliation bancaire"
                is-red-label
                :disabled="isAdminClub"
              ></Textarea>

              <!-- <Textarea
                rules="required"
                v-model="titulaire"
                twoLines
                label="Nom du titulaire"
                is-red-label
                :disabled="isAdmin"
              ></Textarea> -->
              <Textarea
                rules="required"
                v-model="titulaire"
                twoLines
                label="Nom du titulaire"
                is-red-label
                :disabled="isAdminClub"
              ></Textarea>

              <!-- <InputText
                v-model="numero_iban"
                label="Numéro IBAN"
                :disabled="isAdmin"
              ></InputText> -->
              <InputText v-model="numero_iban" label="Numéro IBAN" :disabled="isAdminClub"></InputText>
              <!-- <InputText
                v-model="numero_bic"
                label="Numéro BIC"
                :disabled="isAdmin"
              ></InputText> -->
              <InputText v-model="numero_bic" label="Numéro BIC" :disabled="isAdminClub"></InputText>
            </div>

            <div class="form-user-details__action">
              <!-- <v-btn
                type="submit"
                class="btn btn-primary mt-5"
                :disabled="isAdmin || isSubmitingRIB || !canSubmit"
                :loading="isSubmitingRIB"
                >Mettre à jour</v-btn
              > -->
              <v-btn
                type="submit"
                class="btn btn-primary mt-5"
                :disabled="isSubmitingRIB || !canSubmit"
                :loading="isSubmitingRIB"
                v-if="!isAdminClub"
                >Mettre à jour</v-btn
              >
            </div>
          </ValidateForm>
        </div>
      </div>

      <div v-if="!isAdminClub" class="d-flex align-center justify-space-around mt-5">
        <div class="d-flex align-center">
          <span
            v-text="
              `Prélèvement FIRST ou RECURENT : ${structure &&
                structure.prelevement}`
            "
          />
          <v-btn icon color="blue-primary">
            <v-icon @click="setType">mdi-swap-horizontal-circle</v-icon>
          </v-btn>
        </div>
        <div class="d-flex align-center">
          <span v-text="`Code RUM : ${codeRum}`" />
          <v-btn icon color="blue-primary">
            <v-icon @click="setRum">mdi-pencil-circle</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import SubBar from "@/components/Common/Common__SubBar.vue";
import ValidateForm from "@/components/utils/form.vue";
import InputText from "@/components/utils/input.vue";
import Textarea from "@/components/utils/textarea.vue";
import Loading from "@/components/LoadingIndicator.vue";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { RIB, SEPA_MANDATE } from "../../../constants/structureDocumentType";
import { getStructureDocumentsByStructureId } from "../../../plugins/structureService";
import DocumentType from "@/components/GestionDesDocuments/GestionDesDocuments__Type.vue";

export default {
  mixins: [validationMixin],
  components: {
    // SubBar,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
    ValidateForm,
    InputText,
    Textarea,
    Loading,
    DocumentType,
  },
  data: () => ({
    changedDate: new Date().toISOString().substr(0, 10),
    prelevementstype: [],
    selectedEvent: {},
    selectedType: "",
    titulaire: "",
    domicilebancaire: "",
    numero_iban: "",
    numero_bic: "",
    allDocumentType: [],
    fileRIB: {
      name: "test.pdf",
    },
    fileMandat: {},
    rib: [],
    structure: {},
    ribType: "",
    codeRum: "",
    isLoading: true,
    documentRIB: "",
    documentSEPA: "",
    loading: false,
    uploadPercentage: 0,
    fileRIBBlob: null,
    fileSEPABlob: null,
    fileRibChanged: false,
    fileSEPAChanged: false,
    isSubmitingRIB: false,
  }),
  validations: {
    fileRIBBlob: {
      required,
    },
    fileSEPABlob: {
      required,
    },

    domicilebancaire: {
      required,
    },
    titulaire: {
      required,
    },
    structureID: null,
  },

  async created() {
    await this.setData();
    this.isLoading = false;
  },
  computed: {
    ...mapGetters("structure", [
      "currentStructureId",
      "currentStructure",
      "getStructure",
    ]),
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapGetters("user", ["getRole"]),

    subBarTitle() {
      return "Gestion type de règlement et RIB";
    },

    isAdmin() {
      let role = this.getRole.SlugRole;
      if (role === "super_administrateur") return false;
      return true;
    },
    isAdminClub() {
      let role = this.getRole.SlugRole;

      if (
        role === 'club_administrateur' ||
        role === 'ligue_administrateur' ||
        role === 'ea_administrateur' ||
        role === 'administrateur_ma' ||
        role === 'ct_administrateur'
      ) {
        return true;
      }

      return false;
    },
    canSubmit() {
      if (
        this.domicilebancaire != "" &&
        this.titulaire != "" &&
        this.numero_iban != "" &&
        this.numero_bic != ""
      ) {
        return true;
      }
      return false;
    },

    DOC_Rib() {
      const strDocRib = this.structureDocuments?.find(doc => doc.type === RIB);
      const rib = this.rib[0]?.dOCDocumentByIdDocRib;

      if (strDocRib && rib) {
          return new Date(strDocRib.createdAt) > new Date(rib.Z_DateCreation)
              ? { data : strDocRib, isNewDoc: true }
              : { data: rib, isNewDoc: false };
      } else if (strDocRib) {
          return { data : strDocRib, isNewDoc: true };
      } else if (rib) {
          return { data: rib, isNewDoc: false };
      }

      return this.getDefaultDoc("rib");
    },

    DOC_MandatSepa() {
      const strDocSepaMandate = this.structureDocuments?.find(doc => doc.type === SEPA_MANDATE);
      const mandatSepa = this.rib[0]?.dOCDocumentByIdDocSepa;

      if (strDocSepaMandate && mandatSepa) {
          return new Date(strDocSepaMandate.createdAt) > new Date(mandatSepa.Z_DateCreation)
              ? { data : strDocSepaMandate, isNewDoc: true }
              : { data: mandatSepa, isNewDoc: false };
      } else if (strDocSepaMandate) {
          return { data : strDocSepaMandate, isNewDoc: true };
      } else if (mandatSepa) {
          return { data: mandatSepa, isNewDoc: false };
      }

      return this.getDefaultDoc("mandat_sepa");
    },

    allDocuments() {
      return [this.DOC_Rib, this.DOC_MandatSepa];
    },
  },

  methods: {
    ...mapActions("finances", ["getPrelevements"]),
    ...mapActions("structure", [
      "getRibByStructure",
      "paymentModalityModification",
      "insertRib",
      "createUpdateRibInfos",
      "getStructureByID",
      "updateTypePrelevement",
      "updateCodeRum",
    ]),
    ...mapActions("basics", ["getListTypesDocument", "insertDocument"]),

    getDefaultDoc(slugType) {
      let doc = {
        CheminDocument: "",
        DateFinValiditeDocument: "",
        EST_Actif: true,
        EST_DocumentValide: false,
        ID_Structure: this.structureID,
        ID_Type_Document: "",
        NomDocument: "",
      };

      let type = this.allDocumentType.find(
        (doc) => doc.SlugTypeDocument == slugType
      );

      doc.ID_Type_Document = type?.id;

      return { data: doc, isNewDoc: false };
    },

    // file downloader
    onFileChange(filePointer, file, type) {
      const fileTypeAccepted = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "application/pdf",
      ];
      if (file == null) {
        return;
      }
      if (!fileTypeAccepted.includes(file.type)) {
        return error_notification(
          "Veuillez téléverser le bon format de fichier (jpeg,pdf,docx)."
        );
      }
      filePointer.name = file.name;
      // read file data to memory
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          filePointer.fileContent = event.target.result;
        };
        reader.readAsDataURL(file);
        this.fileChanged(type);
        return (this.fileLoaded = false);
      }
      this.fileLoaded = false;
      return alert("Sorry, FileReader API not supported");
    },
    fileChanged(context) {
      if (context === "RIB") return (this.fileRibChanged = true);
      return (this.fileSEPAChanged = true);
    },
    async uploadFile(fileToUpload, fileType) {
      this.loading = true;
      const hash =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15);

      let payload = {
        image: fileToUpload.fileContent,
        key: `${this.structureID}_${hash}_${fileToUpload.name}`,
        config: {
          S3_BUCKET: process.env.S3_BUCKET,
        },
      };

      this.uploadPercentage = 0;
      try {
        const response = await this.axios.post(
          process.env.VUE_APP_FFME_BACK_URL + "/api/media-upload",
          payload,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
            },
            onUploadProgress: (progressEvent) => {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            },
          }
        );
        if (
          response.data &&
          response.data.location &&
          response.status &&
          response.status === 200
        ) {
          let freshFile = {
            url: response.data.location,
            filename: `rib_${fileToUpload.name}`,
          };

          this.loading = false;
          return freshFile;
        }
        this.loading = false;
        return error_notification("Upload unsuccessful");
      } catch (error) {
        this.loading = false;
        error_notification(error.toString());
      }
    },

    async setData() {
      this.structureID = parseInt(this.$route.params.id);
      if (!this.structureID) this.structureID = this.currentStructureId;
      console.log("this.structureID *********", this.structureID);

      this.prelevementstype = await this.getPrelevements();
      this.allDocumentType = await this.getListTypesDocument();
      this.structure = await this.getStructureByID(this.structureID);

      this.selectedType = this.structure.LIST_TypePrelevement?.id;
      let value = {
        ID_Structure: this.structureID,
      };
      this.rib = await this.getRibByStructure(value);

      if (this.rib?.length > 0) await this.initialize();

      this.structureDocuments = await getStructureDocumentsByStructureId(this.structureID, [RIB, SEPA_MANDATE]);
    },

    async documentUploaded() {
      await this.setData();
    },

    async initialize() {
      const ribValue = this.rib[0];
      console.log("initialize ribValue", ribValue);
      this.ribType = ribValue.LIST_TypePrelevement?.Type;
      console.log(
        "initialize STR_RUM_MODIFICATION",
        ribValue.STR_RUM_MODIFICATION
      );
      this.codeRum = ribValue.STR_RUM_MODIFICATION
        ? ribValue.STR_RUM_MODIFICATION
        : "";
      console.log("initialize codeRum", this.codeRum);
      this.domicilebancaire = ribValue.Domiciliation;
      this.titulaire = ribValue.FIN_NomTitulaire;
      this.numero_iban = ribValue.FIN_IbanNumero;
      this.numero_bic = ribValue.FIN_BicNumero;

      if (ribValue.dOCDocumentByIdDocRib?.CheminDocument) {
        this.fileRIBBlob = await this.getFileFromUrl(
          ribValue.dOCDocumentByIdDocRib?.CheminDocument,
          ribValue.dOCDocumentByIdDocRib?.NomDocument
        );
      }
      if (ribValue.dOCDocumentByIdDocSepa?.CheminDocument) {
        this.fileSEPABlob = await this.getFileFromUrl(
          ribValue.dOCDocumentByIdDocSepa?.CheminDocument,
          ribValue.dOCDocumentByIdDocSepa?.NomDocument
        );
      }
      // this.selectedType = ribValue.LIST_TypePrelevement?.id;
    },

    async submit() {
      try {
        // if (this.rib.length === 0) {
        //   let payload = {
        //     ID_Structure: this.currentStructureId,
        //     Date_modification_mode: this.changedDate,
        //     ID_Prelevement_type: this.selectedType,
        //   };

        //   await this.insertRib(payload);
        // } else {
        let payload = {
          structureID: this.structureID,
          datemodification: this.changedDate,
          idModalite: this.selectedType,
        };
        await this.paymentModalityModification(payload);
        // }
        success_notification("Modalité de paiement modifiée");

        const selectedmodality = this.prelevementstype.filter(
          (prel) => prel.id === this.selectedType
        );
        this.ribType = selectedmodality[0].Type;
      } catch (e) {
        error_notification("Oups, une erreur est survenue...");
      }
    },

    async submitRib() {
      this.isSubmitingRIB = true;
      const documentTypeRib = this.allDocumentType.filter(
        (document) => document.id === 27
      );
      const rib = await this.getRibByStructure({
        ID_Structure: this.structureID,
      });
      try {
        const payload = {
          id: this.rib && this.rib[0]?.id,
          ID_Structure: this.structureID,
          Domiciliation: this.domicilebancaire,
          FIN_NomTitulaire: this.titulaire,
          FIN_IbanNumero: this.numero_iban,
          FIN_BicNumero: this.numero_bic,
        };

        if (this.fileRibChanged) {
          const docRIB = await this.insertFileInDB(
            this.fileRIB,
            documentTypeRib[0],
            "rib"
          );
          payload.ID_DOC_RIB = docRIB.id;
        }
        if (this.fileSEPAChanged) {
          const documentTypeSepa = this.allDocumentType.filter(
            (document) => document.id === 52
          );
          // mandat sepa file
          const docSEPA = await this.insertFileInDB(
            this.fileMandat,
            documentTypeSepa[0],
            "mandatSepa"
          );
          payload.ID_DOC_SEPA = docSEPA.id;
        }
        if (rib.length === 0) {
          await this.insertRib(payload);
        } else {
          await this.createUpdateRibInfos(payload);
        }

        success_notification("Enregistrement effectué");
        await this.setData();
      } catch (e) {
        error_notification("Oups, une erreur est survenue...");
      }
      this.isSubmitingRIB = false;
    },

    onRibChange(file) {
      this.fileRIB = file;
    },

    onMandatChange(file) {
      this.fileMandat = file;
    },

    async insertFileInDB(file, documentType, fileType) {
      // upload file to S3 before inserting in hasura
      const fileUploaded = await this.uploadFile(file, fileType);
      //
      let payload = {
        CheminDocument: fileUploaded.url,
        NomDocument: file.name,
        DocumentType: documentType,
        ID_Structure: this.structureID,
        DateValidation: this.$moment(file.lastModifiedDate).toISOString(),
        ID_Saison: this.currentSaison.id,
        EST_Actif: true,
        EST_DocumentValide: true,
      };
      try {
        const response = await this.insertDocument(payload);
        return response;
      } catch (e) {
        error_notification(
          "Oups, une erreur est survenue lors du téléchargement du document..."
        );
      }
    },

    async setType() {
      let typeprel = "";
      if (this.structure.prelevement === "FIRST") typeprel = "RCUR";
      else typeprel = "FIRST";

      const payload = {
        type: typeprel,
        structureID: this.structureID,
      };

      await this.updateTypePrelevement(payload);
      this.structure.prelevement = typeprel;
    },

    async setRum() {
      let newCodeRum = "";
      if (this.codeRum === 0 || this.codeRum === null)
        newCodeRum = this.structureID + "";
      else {
        const currentRumExtension = this.codeRum.split("_")[1];
        if (currentRumExtension) {
          const rumExtension = parseInt(currentRumExtension) + 1;
          newCodeRum = this.structureID + "_" + rumExtension;
        } else newCodeRum = this.structureID + "_" + 1;
      }
      const payload = {
        codeRum: newCodeRum,
        structureID: this.structureID,
      };

      await this.updateCodeRum(payload);
      this.codeRum = newCodeRum;
    },

    async getFileFromUrl(url, fileName) {
      var res = await fetch(url);
      var blob = await res.blob();
      var uri = await this.parseURI(blob);
      return new File([uri], fileName);
    },
    async parseURI(d) {
      var reader = new FileReader();
      reader.readAsDataURL(d);
      return new Promise((res) => {
        reader.onload = (e) => {
          res(e.target.result);
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  background: red;
}
.link-url {
  font-family: Roboto;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 300;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #082b6e;
  line-height: 24px;
}
.file-container {
  width: fit-content;
}
.input-item {
  max-width: 415px !important;
}
::v-deep .input-item .v-text-field__details {
  display: none;
}
</style>
