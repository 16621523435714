import axios from "axios";
const FFME_ID = '1318';
const OPTION_TYPE_ASSURANCE = 'assurance';
const OPTION_TYPE_GROUPE = 'groupe';
const PRODUCT_TYPE_LICENCE = 'licence';
const PAYMENT_TYPE_CASH = 'cash';
const PAYMENT_TYPE_LEMONWAY = 'lemonway';
const DESTINATION_TYPE_STRUCTURE = 'structure';
const ORIGIN_TYPE_USER = 'user';
const ORIGIN_TYPE_STRUCTURE = 'structure';
const ORIGIN_TYPE_LICENCE_DECOUVERTE = 'licence_decouverte';
const OPERATION_ACHAT_LICENCE = 'achat_licence';
const OPERATION_UPDATE_ASSURANCE = 'update_assurance';
const OPERATION_UPDATE_LICENCE = 'update_licence';
const OPERATION_ANNULATION_LICENCE = 'annulation_licence';
const OPERATION_ACHAT_ASSURANCE_VOYAGE = 'achat_assurance_voyage';
const OPERATION_ACHAT_LICENCE_DECOUVERTE = 'achat_licence_decouverte';
const OPERATION_ANNULATION_LICENCE_DECOUVERTE = 'annulation_licence_decouverte';
const OPERATION_UPDATE_LICENCE_DECOUVERTE = 'update_licence_decouverte';
const OPERATION_AFFILIATION = 'affiliation';
const headers = {
  'Content-Type': 'application/json',
  'X-AUTHFFME-TOKEN': process.env.VUE_APP_FFME_BACK_AUTH_TOKEN
};

const insertFlux = async (params) => {
  try {
    console.log('---------- INSERT FLUX START ----------')
    console.log('payload', params)
    const response = await axios.post(
      process.env.VUE_APP_FFME_BACK_URL + "/api/add_flux",
      params,
      {
        headers: headers
      }
    );
    const json = await response.data;
    console.log('response', json)
    console.log('---------- INSERT FLUX END ----------')
    return { ok: true };
  } catch (error) {
    console.log("insertFlux error :", error);
    console.log('---------- INSERT FLUX END ----------')
    return { ok: false };
  }
};

const formatFluxFromLicenceModal = (input, operation) => {
  // console.log('formatFluxFromLicenceModal',input)
  const options = [];
  if (input.UTI_LicenceAssurance && input.UTI_LicenceAssurance.length > 0) {
    input.UTI_LicenceAssurance.forEach(option => {
      if (option.ID_TypeAssurance || option.ASSU_AssuranceType.id) {
        options.push({
          option_type: OPTION_TYPE_ASSURANCE,
          option_id: (option.ID_TypeAssurance || option.ASSU_AssuranceType.id).toString()
        });
      }
    });
  }
  if (input.NomGroupe) {
    options.push({
      option_type: OPTION_TYPE_GROUPE,
      option_id: input.NomGroupe
    });
  }
  const product = {
    product_id: input.ID_TypeLicence.toString(),
    product_type: PRODUCT_TYPE_LICENCE
  };
  return {
    operation: operation === 'cancel' ? OPERATION_ANNULATION_LICENCE : OPERATION_ACHAT_LICENCE,
    paymentType: PAYMENT_TYPE_CASH,
    originId: input.ID_Utilisateur,
    originType: ORIGIN_TYPE_USER,
    destinationId: input.ID_Structure.toString(),
    destinationType: DESTINATION_TYPE_STRUCTURE,
    memberLicenceId: input.id.toString(),
    seasonId: input.ID_Saison.toString(),
    product,
    productOptions: options
  };
}

const formatFluxFromLicenceHCLemonway = (input) => {
  // console.log('formatFluxFromLicenceHCLemonway', input)
  const options = [];
  if (input.UTI_LicenceAssurance && input.UTI_LicenceAssurance.length > 0) {
    input.UTI_LicenceAssurance.forEach(option => {
      if (option.ID_TypeAssurance) {
        options.push({
          option_type: OPTION_TYPE_ASSURANCE,
          option_id: option.ID_TypeAssurance.toString()
        });
      }
    });
  }
  if (input.NomGroupe) {
    options.push({
      option_type: OPTION_TYPE_GROUPE,
      option_id: input.NomGroupe
    });
  }
  const product = {
    product_id: input.ID_TypeLicence.toString(),
    product_type: PRODUCT_TYPE_LICENCE
  };
  return {
    operation: OPERATION_ACHAT_LICENCE,
    paymentType: PAYMENT_TYPE_LEMONWAY,
    originId: input.ID_Utilisateur,
    originType: ORIGIN_TYPE_USER,
    destinationId: FFME_ID,
    destinationType: DESTINATION_TYPE_STRUCTURE,
    memberLicenceId: input.id.toString(),
    seasonId: input.ID_Saison.toString(),
    product,
    productOptions: options
  };
}

const formatFluxFromLicenceDecouverte = (licenceId, currentSaisonId, currentStructureId, operation) => {
  // console.log('TODO : formatFluxFromLicenceDecouverte', licenceId, currentSaisonId, currentStructureId)

  return {
    operation: operation === 'cancel' ? OPERATION_ANNULATION_LICENCE_DECOUVERTE : OPERATION_ACHAT_LICENCE_DECOUVERTE,
    paymentType: PAYMENT_TYPE_CASH,
    originId: licenceId.toString(),
    originType: ORIGIN_TYPE_LICENCE_DECOUVERTE,
    destinationId: currentStructureId.toString(),
    destinationType: DESTINATION_TYPE_STRUCTURE,
    seasonId: currentSaisonId.toString(),
  };
}

const formatFluxFromUpdateLicenceDecouverte = (licenceId, currentSaisonId, currentStructureId, fromDay, toDay) => {
  // console.log('formatFluxFromUpdateLicenceDecouverte', licenceId, currentSaisonId, currentStructureId, fromDay, toDay)

  return {
    operation: OPERATION_UPDATE_LICENCE_DECOUVERTE,
    paymentType: PAYMENT_TYPE_CASH,
    originId: licenceId.toString(),
    originType: ORIGIN_TYPE_LICENCE_DECOUVERTE,
    destinationId: currentStructureId.toString(),
    destinationType: DESTINATION_TYPE_STRUCTURE,
    seasonId: currentSaisonId.toString(),
    fromElements: [
      {
        quantity: fromDay
      }
    ],
    toElements: [
      {
        quantity: toDay
      }
    ]
  };
}

const formatFluxFromModalUpdateOptionsAssurance = (licence, assuranceType, assuranceIJ, assuranceOption, commission) => {
  // console.log('formatFluxFromModalUpdateOptionsAssurance', licence, assuranceType, assuranceIJ, assuranceOption)
  let fromAssurances = {};
  let toAssurances = {};
  if (licence.UTI_LicenceAssurance && licence.UTI_LicenceAssurance.length > 0) {
    licence.UTI_LicenceAssurance.forEach(item => {
      const catId = item.ASSU_AssuranceType.ASSU_AssuranceCategorie.id;
      if (!fromAssurances[catId]) {
        fromAssurances[catId] = [];
      }
      fromAssurances[catId].push(item.ASSU_AssuranceType.id)
    });
  }

  if (assuranceType) {
    const catAssType = assuranceType?.LIST_TarifCategorie?.AssuranceType?.ID_AssuranceCategorie
    if (!toAssurances[catAssType]) {
      toAssurances[catAssType] = [];
    }
    toAssurances[catAssType].push(assuranceType?.LIST_TarifCategorie?.AssuranceType?.id)
  }
  if (assuranceIJ) {
    const catAssIJ = assuranceIJ?.LIST_TarifCategorie?.AssuranceType?.ID_AssuranceCategorie
    if (!toAssurances[catAssIJ]) {
      toAssurances[catAssIJ] = [];
    }
    toAssurances[catAssIJ].push(assuranceIJ?.LIST_TarifCategorie?.AssuranceType?.id)
  }

  let catAssuranceId;
  if (assuranceOption && assuranceOption.length > 0) {
    assuranceOption.forEach(item => {
      catAssuranceId = item?.LIST_TarifCategorie?.AssuranceType?.ID_AssuranceCategorie;
      if (!toAssurances[catAssuranceId]) {
        toAssurances[catAssuranceId] = [];
      }
      toAssurances[catAssuranceId].push(item?.LIST_TarifCategorie?.AssuranceType?.id)
    });
  }

  const fromAssurancesFormatted = [];
  const toAssurancesFormatted = [];
  const assType = [1, 2, 3];
  for (let key in fromAssurances) {
    fromAssurancesFormatted.push(...fromAssurances[key].map(item => {
      return {
        type: OPTION_TYPE_ASSURANCE,
        id: item
      }
    }));
  }

  for (let key of assType) {
    // si c'est les options (2) on ajoute from et to
    if (key === 2) {
      if (toAssurances[key]) {
        toAssurancesFormatted.push(...toAssurances[key].map(item => {
          return {
            type: OPTION_TYPE_ASSURANCE,
            id: item
          }
        }));
      }
      if (fromAssurances[key]) {
        toAssurancesFormatted.push(...fromAssurances[key].map(item => {
          return {
            type: OPTION_TYPE_ASSURANCE,
            id: item
          }
        }));
      }
    } else if (toAssurances[key]) {
      // si c'est IJ ou base et qu'on a un changement
      toAssurancesFormatted.push(...toAssurances[key].map(item => {
        return {
          type: OPTION_TYPE_ASSURANCE,
          id: item
        }
      }));
    } else if (fromAssurances[key]) {
      // si on a pas de changement on prend le from
      toAssurancesFormatted.push(...fromAssurances[key].map(item => {
        return {
          type: OPTION_TYPE_ASSURANCE,
          id: item
        }
      }));
    }
  }

  return {
    operation: OPERATION_UPDATE_ASSURANCE,
    paymentType: commission ? PAYMENT_TYPE_LEMONWAY : PAYMENT_TYPE_CASH,
    originId: licence.ID_Utilisateur,
    originType: ORIGIN_TYPE_USER,
    destinationId: licence.ID_Structure.toString(),
    destinationType: DESTINATION_TYPE_STRUCTURE,
    memberLicenceId: licence.id.toString(),
    seasonId: licence.ID_Saison.toString(),
    fromElements: fromAssurancesFormatted,
    toElements: toAssurancesFormatted,
  };
}

const formatFluxFromLineHistoriqueLicenceDeleteOptionsAssurance = (licence, assurances, assuranceToDelete) => {
  // console.log('formatFluxFromLineHistoriqueLicenceDeleteOptionsAssurance', licence, assurances, assuranceToDelete)
  const fromAssurancesFormatted = [];
  const toAssurancesFormatted = [];
  fromAssurancesFormatted.push(...assurances.map(item => {
    return {
      type: OPTION_TYPE_ASSURANCE,
      id: item.ASSU_AssuranceType.id
    }
  }));
  toAssurancesFormatted.push(...assurances.filter(ass => ass.ASSU_AssuranceType.id !== assuranceToDelete.ASSU_AssuranceType.id).map(item => {
    return {
      type: OPTION_TYPE_ASSURANCE,
      id: item.ASSU_AssuranceType.id
    }
  }));

  return {
    operation: OPERATION_UPDATE_ASSURANCE,
    paymentType: PAYMENT_TYPE_CASH,
    originId: licence.UTI_Utilisateur.id,
    originType: ORIGIN_TYPE_USER,
    destinationId: licence.STR_Structure.id.toString(),
    destinationType: DESTINATION_TYPE_STRUCTURE,
    memberLicenceId: licence.id.toString(),
    seasonId: licence.ID_Saison.toString(),
    fromElements: fromAssurancesFormatted,
    toElements: toAssurancesFormatted,
  };
}

const formatFluxFromAssuranceListModifyLicenceType = (licence, toLicenceId) => {
  // console.log('formatFluxFromAssuranceListModifyLicenceType', licence, toLicenceId)

  return {
    operation: OPERATION_UPDATE_LICENCE,
    paymentType: PAYMENT_TYPE_CASH,
    originId: licence.ID_Utilisateur,
    originType: ORIGIN_TYPE_USER,
    destinationId: licence.ID_Structure.toString(),
    destinationType: DESTINATION_TYPE_STRUCTURE,
    memberLicenceId: licence.id.toString(),
    seasonId: licence.ID_Saison.toString(),
    fromElements: [{
      id: licence.ID_TypeLicence,
      type: 'licence'
    }],
    toElements: [{
      id: toLicenceId,
      type: 'licence'
    }],
  };
}


const formatFluxFromVoyageModal = (context) => {
  // console.log('formatFluxFromVoyageModal', context)

  return {
    operation: OPERATION_ACHAT_ASSURANCE_VOYAGE,
    paymentType: PAYMENT_TYPE_LEMONWAY,
    originId: context.currentUser.id,
    originType: ORIGIN_TYPE_USER,
    destinationId: context.currentStructure.ID_Structure.toString(),
    destinationType: DESTINATION_TYPE_STRUCTURE,
    memberLicenceId: context.currentUser.UTI_Licences.find(
      (licence) => licence.ID_Saison === context.currentSaison.id
    )?.id?.toString(),
    seasonId: context.currentSaison.id.toString(),
  };
}

const formatFluxFromAffiliation = ({ structureId, seasonId, commission, createdAt }) => {
  // console.log('formatFluxFromAffiliation', structureId, seasonId);

  return {
    operation: OPERATION_AFFILIATION,
    paymentType: commission ? PAYMENT_TYPE_LEMONWAY : PAYMENT_TYPE_CASH,
    originId: structureId.toString(),
    originType: ORIGIN_TYPE_STRUCTURE,
    seasonId: seasonId.toString(),
    createdAt,
  };
}


export {
  insertFlux,
  formatFluxFromLicenceModal,
  formatFluxFromLicenceHCLemonway,
  formatFluxFromLicenceDecouverte,
  formatFluxFromUpdateLicenceDecouverte,
  formatFluxFromModalUpdateOptionsAssurance,
  formatFluxFromLineHistoriqueLicenceDeleteOptionsAssurance,
  formatFluxFromAssuranceListModifyLicenceType,
  formatFluxFromVoyageModal,
  formatFluxFromAffiliation
};
