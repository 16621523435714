<template>
  <div>
    <div v-if="loading">Chargement...</div>
    <v-row align="center" align-items="center" v-if="!loading">
      <v-col cols="12" md="3" v-if="label" class="field__label-column">
        <label for
          >{{ label }}
          <span>
            {{ isRequired ? '*' : '' }}</span
          ></label
        >
      </v-col>
      <v-col cols="12" md="9" class="field__value-column">
        <div v-if="error" class="error-message">{{ error }}</div>
        <v-autocomplete
          v-if="!error"
          :items="departments"
          v-model="value"
          item-text="label"
          item-value="value"
          solo
          dense
          small-chips
          @change="onChange"
        >
          <template v-slot:item="{ item }" style="height: 10px;">
            <v-list-item-content style="padding: 0;">
              <v-list-item-title class="d-flex align-center">
                {{ item.label }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-slot:selection="{ item }">
            <div class="v-select__selections" style="padding: 0 !important;">
              <div
                class="v-select__selection v-select__selection--comma"
                style="max-width: none; padding: 0;"
              >
                {{ item.label }}
              </div>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { getDepartments } from '@/plugins/departmentService';
import { error_notification } from '@/plugins/notifications';

export default {
  data: () => ({
    loading: true,
    rawDepartments: [],
    departments: [],
    error: undefined,
    value: undefined,
  }),

  async created() {
    if (this.defaultValue) {
      this.value = this.defaultValue;
    }

    await getDepartments().then((response) => {
      this.rawDepartments = response.data.['hydra:member'];
      this.departments = this.rawDepartments.map((department) => ({
        value: `${department.id}`,
        label: `${department.id} - ${department.label}`,
      }));
    }).catch((error) => {
      this.error = 'Une erreur est survenue lors du chargement des départements';
    });

    this.loading = false;
  },

  methods: {
    onChange(value) {
      if (!value) {
        return;
      }

      this.$emit('onChange', this.rawDepartments.find((department) => department.id === value));
    }
  },

  props: {
    label: {
      type: String,
      default: 'Departement',
    },
    defaultValue: {
      type: String,
      default: undefined,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.error-message {
  font-size: 14px;
  color: var(--danger);
}
</style>
