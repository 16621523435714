<template>
  <v-dialog
    v-if="modalIsOpen"
    v-model="modalIsOpen"
    @click:outside="closeModal"
    max-width="550px"
  >
    <v-tabs background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>

      <v-tab style="display: flex; flex-direction: column;">
        <span style="font-size: 1.2rem;">
          {{ modalTitle }}
        </span>
      </v-tab>
      <!-- <v-icon class="close-icon" @click="closeModal">mdi-close</v-icon> -->
    </v-tabs>
    <div class="modal__content">
      <v-tabs-items>
        <div v-if="!mounted" class="text-center py-6">Chargement...</div>
        <div v-else>
          <!-- <div v-if="errors.length" class="text-center py-6">
            <div class="text--red">
              <ul style="padding-left: 0; padding: 0 10px;">
                <li
                  v-if="users.length > 1"
                  class="font-weight-bold red--text mb-2"
                >
                  Impossible de valider les licences
                </li>
                <li v-else class="font-weight-bold red--text mb-2">
                  Impossible de valider la licence
                </li>
                <li v-for="(error, index) in errors" :key="index" class="">
                  <span v-html="error.error"></span>
                </li>
              </ul>
            </div>
          </div> -->
          <v-card flat class="px-8 py-4">
            <div>
              <!-- <h5 class="pb-6">Détails de la licence et montant à régler</h5> -->

              <div class="text-center pb-4">
                <span class="font-weight-bold">Saison : </span>
                <v-chip class="ml-2" small>{{ saison.label }} </v-chip>

                <p class="font-weight-bold mt-2" v-if="licenceCount">
                  Création de {{ licenceCount }} licences
                </p>
                <v-divider v-if="nomduGroupe.length !== 0"></v-divider>
              </div>

              <!-- Cotisation club -->
              <div class="mt-n4" v-if="nomduGroupe.length !== 0">
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold"
                      >{{ nomduGroupe && nomduGroupe.NomGroupe }}:</span
                    >
                  </div>
                  <div class="col">
                    <v-chip outlined color="black" small
                      >{{
                        nomduGroupe &&
                        nomduGroupe.STR_GroupeSaisonCreneau[0].MKP_Tarif
                          .MontantUnitaire
                      }}€
                    </v-chip>
                  </div>
                </div>
              </div>

              <!-- Options adhesions -->

              <div
                class="mt-n4"
                v-for="(opt, i) in cotisationOptionAdhesion"
                :key="i"
              >
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold"
                      >{{ opt && opt.NomTarif }} :</span
                    >
                  </div>
                  <div class="col">
                    <v-chip outlined color="black" small
                      >{{ opt && opt.MontantUnitaire }}€
                    </v-chip>
                  </div>
                </div>
              </div>
              <v-divider class="mt-n1" v-if="cotisationCT"></v-divider>
              <!-- Cotisation CT -->
              <div
                class="mt-n4"
                v-if="cotisationCT && cotisationCT.MontantUnitaire"
              >
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold"
                      >{{ cotisationCT && cotisationCT.NomTarif }} :</span
                    >
                  </div>
                  <div class="col">
                    <v-chip class="mx-1" outlined color="black" small
                      >{{ cotisationCT && cotisationCT.MontantUnitaire }}€
                    </v-chip>
                  </div>
                </div>
              </div>
              <div class="mt-n4" v-else>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Cotisation :</span>
                  </div>
                  <div class="col">
                    <v-chip class="mx-1" outlined color="black" small
                      >0€
                    </v-chip>
                  </div>
                </div>
              </div>
              <!--    Cotisation Ligue -->
              <div
                class="mt-n4"
                v-if="cotisationLigue && cotisationLigue.MontantUnitaire"
              >
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold"
                      >{{ cotisationLigue && cotisationLigue.NomTarif }} :</span
                    >
                  </div>
                  <div class="col">
                    <v-chip class="mx-1" outlined color="black" small
                      >{{ cotisationLigue && cotisationLigue.MontantUnitaire }}€
                    </v-chip>
                  </div>
                </div>
              </div>
              <div class="mt-n4" v-else>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold"
                      >Cotisation licence Ligue:</span
                    >
                  </div>
                  <div class="col">
                    <v-chip class="mx-1" outlined color="black" small
                      >0€
                    </v-chip>
                  </div>
                </div>
              </div>
              <v-divider class="mt-n1"></v-divider>
              <!-- Licence type , adulte, jeune, famille  -->
              <div class="mt-n4">
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Licence :</span>
                  </div>
                  <div class="col">
                    <v-chip outlined color="blue" small>
                      {{ licencetype ? licencetype : "" }}
                      -
                      <span class="font-weight-bold"
                        >{{ licenceMontant ? licenceMontant : "nc" }}€</span
                      >
                    </v-chip>
                  </div>
                </div>
              </div>

              <!-- Maion assurance RC, BASE, BASE + , BASE ++  -->
              <div class="mt-n4">
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Assurance :</span>
                  </div>
                  <div class="col">
                    <v-chip outlined color="orange" small>
                      {{ assuranceType ? assuranceType : "" }}
                      -
                      <span class="font-weight-bold"
                        >{{ assuranceMontant ? assuranceMontant : "nc" }}€</span
                      >
                    </v-chip>
                  </div>
                </div>
              </div>

              <!-- Option assu, ski, slack, trail, ctt, ij1, ij2, ij3 -->
              <div class="mt-n4">
                <div v-if="typeAssurance.length" class="row">
                  <div class="col">
                    <p class="font-weight-bold">Option(s) d'assurance :</p>
                  </div>
                  <div class="col">
                    <p v-for="(option, index) in typeAssurance" :key="index">
                      <v-chip class="mx-1" outlined color="green" small>
                        {{ option.NomTarif }} &nbsp;
                        <span class="font-weight-bold"
                          >{{ option.MontantUnitaire }}€</span
                        >
                      </v-chip>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <div class="row">
                <div class="col"></div>
                <div class="col">
                  <span
                    class="font-weight-bold gray--text text--darken-4"
                    style="position: relative;"
                  >
                    Total :</span
                  >
                  <span
                    class="font-weight-bold indigo--text text--darken-4"
                    style="font-size: 1.8rem;"
                  >
                    {{ getTotal }}€
                  </span>
                </div>
              </div>
            </div>

            <v-card-actions class="pb-4 pt-10">
              <v-spacer></v-spacer>
              <v-btn
                @click="closeModal"
                gray
                rounded
                color="gray text-black font-weight-bold"
                >Annuler</v-btn
              >
              <v-btn
                @click="onConfirm"
                :loading="loading"
                rounded
                color="blue-dark text-white font-weight-bold"
                >Confirmer
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    loading: false,
    mounted: false,
    tarifs: {},

    fluxFinancier: [],

    tarifsAlreadyPayed: [],
    fluxData: [],

    tarifsFFME: [],

    ID_CT: null,
    ID_Ligue: null,

    errors: [],

    idsAssurances: [],
    idsParents: [],

    errorsOnUsers: [],
    cotisationOptionAdhesion: [],

    choosenLicence: {},
    choosenAssurance: {},

    licencetype: "",
    assuranceType: "",
    licenceMontant: 0,
    assuranceMontant: 0,

    typeAssurance: [],
    choosenTarifs: [],
  }),

  async created() {
    this.tarifsFFME = await this.getTarifsFFME({
      ID_Saison: this.data.saison,
    });
    console.log("this.tarifsFFME", this.tarifsFFME);
    console.log("this.data", this.data);

    this.choosenLicence = this.tarifsFFME.find(
      (tarif) => tarif.LIST_TarifCategorie.ID_TypeLicence == this.data.licence
    );
    console.log("this.choosenLicence", this.choosenLicence);

    this.licencetype = this.choosenLicence?.LIST_TarifCategorie?.LicenceType.NomTypeLicence;
    this.licenceMontant = this.choosenLicence?.MontantUnitaire;

    this.choosenAssurance = this.tarifsFFME.find(
      (tarif) =>
        tarif.LIST_TarifCategorie.ID_TypeAssurance == this.data.assurance
    );
    this.assuranceType = this.choosenAssurance.LIST_TarifCategorie?.AssuranceType.NomTypeAssurance;
    this.assuranceMontant = this.choosenAssurance.MontantUnitaire;

    this.checkEditMultiple();
    await this.setData();

    this.mounted = true;
    return;
  },

  computed: {
    ...mapGetters("saisons", ["getSaison"]),
    ...mapGetters("structure", ["getGroupeCreneaux", "currentStructureId"]),

    modalTitle() {
      return "Récapitulatif de l'adhesion";
    },

    saison() {
      return this.getSaison(this.data.saison);
    },

    licence() {
      return this.getTarifsLicence(this.data.licence);
    },

    mainAssurance() {
      return this.getTarifsAssurance(this.data.assurance);
    },

    cotisationClub() {
      if (!this.tarifs.length) return;
      return this.tarifs.find((tarif) => tarif.id == this.data.tarif);
    },

    cotisationCT() {
      if (!this.tarifs.length) return;
      return this.tarifs.find((tarif) => tarif.ID_Structure == this.ID_CT);
    },

    cotisationLigue() {
      if (!this.tarifs.length) return;
      return this.tarifs.find((tarif) => tarif.ID_Structure == this.ID_Ligue);
    },

    nomduGroupe() {
      let groupe = [];
      this.getGroupeCreneaux.map((item) => {
        if (this.data.nomGroupe === item.id) {
          groupe = item;
        }
      });
      return groupe;
    },

    getTotal() {
      let total = 0;
      // get option assurance + ij
      this.tarifsFluxDetail.forEach((detail) => {
        if (detail !== undefined) {
          if (this.licenceCount === undefined) {
            total += detail?.MontantUnitaire;
          } else total += detail?.MontantUnitaire * this.licenceCount;
        }
      });
      // this.tarifsToPay.forEach( tarif => {
      //   total += tarif.MontantUnitaire
      // })
      return total;
    },

    tarifsFluxDetail() {
      // prepare the fluxdetail object
      let tarifsToFlux = [
        this.choosenLicence,
        this.choosenAssurance,
        ...this.typeAssurance,
        this.cotisationClub,
        this.cotisationCT,
        this.cotisationLigue,
        ...this.cotisationOptionAdhesion,
      ];

      return tarifsToFlux;
    },
  },

  methods: {
    ...mapActions("tarifs", ["getAllTarifForLicence", "TarifsLicence"]),
    ...mapActions("structure", [
      "getStructureParent",
      "addUserToStructureOnPrimoAffi",
    ]),
    ...mapActions("basics", [
      "getListTypeLicence",
      "getListTypeAssurances",
      "getTarifsFFME",
    ]),

    async setData() {
      let optionAdhesion = this.data.optionAdhesion;
      const optionAdhesionID = this.data.optionAdhesionID
        ? this.data.optionAdhesionID.map((option) => option)
        : [];
      const opts = [...optionAdhesionID];

      if (this.data.optionAdhesion?.length) {
        this.cotisationOptionAdhesion = optionAdhesion.filter((option) => {
          return opts.some((opt) => {
            return option.id === opt;
          });
        });
      }

      // Get the tarif and the flux on all users
      let strParentIDs = await this.getStructureParent({
        ID_Structure: this.currentStructureId,
      });

      // On collecte donc les ids des ligue ct
      this.idsParents = [];
      if (strParentIDs[0]) {
        if (strParentIDs[0].ID_ComiteTerritorial) {
          this.idsParents.push(strParentIDs[0].ID_ComiteTerritorial);
          this.ID_CT = strParentIDs[0].ID_ComiteTerritorial;
        }
        if (strParentIDs[0].ID_Ligue) {
          this.idsParents.push(strParentIDs[0].ID_Ligue);
          this.ID_Ligue = strParentIDs[0].ID_Ligue;
        }
      }
      // Collecter les differents id d'assurance passé
      // On a déjà l'ID de la licence
      if (this.data.optionAssurance.length)
        this.idsAssurances = [
          ...this.idsAssurances,
          ...this.data.optionAssurance,
        ];
      if (this.data.optionAssurancesIJ)
        this.idsAssurances = [
          ...this.idsAssurances,
          ...[this.data.optionAssurancesIJ],
        ];

      try {
        // Récuperer les tous tarifs de la ffme et ensuite faire la comparaison. Juste recuprer le tarif emit par le club/ct et ligue
        // Pour être sure, étant donné qu'un club appartient forcement à un ct, si ce ct n'a pas de tarif, on ne peut pâs continuer....
        this.tarifsFFME = await this.getTarifsFFME({
          ID_Saison: this.data.saison,
        });

        // on recupere ici donc les tarifs des ct ligue etc, mais aussi les flux financier
        let response;
        if (this.data.tarif) {
          response = await this.getAllTarifForLicence({
            context: {
              usersID: this.users.map((us) => {
                return us.ID_Utilisateur;
              }),
              IDS_Structures: this.idsParents,
              ID_Structure: this.currentStructureId,
              ID_Saison: this.data.saison,
              ID_Tarif: this.data.tarif,
            },
          });
        } else {
          response = await this.TarifsLicence({
            context: {
              usersID: this.users.map((us) => {
                return us.ID_Utilisateur;
              }),
              IDS_Structures: this.idsParents,
              ID_Structure: this.currentStructureId,
              ID_Saison: this.data.saison,
            },
          });
        }

        // Et ici, je dois verifier d'avoirt les tarifs de licence, assurane, ct , ligue
        this.tarifs = response.MKP_Tarif;

        // Check if we have a tarif with the same id str oh the ct
        if (
          !this.ID_CT ||
          !this.tarifs.find((tarif) => tarif.ID_Structure == this.ID_CT)
        ) {
          this.errors.push({
            error: "Le tarif de la part du CT est à 0€",
          });
        }

        if (
          !this.ID_Ligue ||
          !this.tarifs.find((tarif) => tarif.ID_Structure == this.ID_Ligue)
        ) {
          this.errors.push({
            error: "Le tarif de la part de la ligue est à 0€",
          });
        }
        this.typeAssurance = this.idsAssurances.map((assu) => {
          return this.tarifsFFME.find(
            (tarif) => tarif.LIST_TarifCategorie.ID_TypeAssurance == assu
          );
        });
        this.choosenTarifs = this.tarifsFluxDetail;

        this.fluxFinancier = response.MKP_FluxFinancier;
        this.setFluxFinancier();
      } catch (e) {
        console.log(e);
      }
      // this.assurancesList = await this.getListTypeAssurances();
      // this.licenceTypeList =  await this.getListTypeLicence();
      return;
    },

    async getTarifsAssurance(id_assu) {
      const assurance = this.tarifsFFME.find(
        (tarif) => tarif.LIST_TarifCategorie.ID_TypeAssurance == id_assu
      );
      return assurance;
    },

    async getTarifsLicence(id_assu) {
      const licence = this.tarifsFFME.find(
        (tarif) => tarif.LIST_TarifCategorie.ID_TypeLicence == id_assu
      );
      return licence;
    },

    closeModal() {
      this.$emit("onClose");
    },

    onConfirm() {
      this.loading = true;
      const response = {
        tarifs: this.tarifsFluxDetail,
        fluxData: this.fluxData,
        loading: true,
      };
      this.$emit("onConfirm", response);
    },

    setFluxFinancier() {
      // We need to pass across all user andcheck if they have a flux on this context
      let data = this.users.map((user) => {
        let fluxUser = this.fluxFinancier.find(
          (f) => f.ID_Utilisateur == user.ID_Utilisateur
        );

        // isolé les flux des ct et ligues que j'ai récupéré
        // create a new flux if the user doesnt have one for this str and saison,
        // if (!fluxUser) {
        fluxUser = {
          ID_FluxFinancierDebit: 0,
          ID_Saison: this.data.saison,
          ID_Structure: this.currentStructureId,
          ID_Traitement: 0,
          ID_Utilisateur: user.ID_Utilisateur,
          MontantHT: 0,
          MontantTTC: 0,
          MontantTVA: 0,
          NomFluxFinancier:
            "Licence " +
            user.UTI_Utilisateur.CT_Prenom +
            " " +
            user.UTI_Utilisateur.CT_Nom +
            " - " +
            this.data.saison,
          EST_Reglement: false,
          MKP_FluxFinancierDetail: {
            data: [],
            on_conflict: {
              constraint: "MKP_FluxFinancierDetail_pkey",
              update_columns: ["ID_Tarif"],
            },
          },
        };

        this.choosenTarifs.forEach((tarif) => {
          // if the user has already this flux detail, update it
          // usefull for later
          // let hasAlready = fluxUser.MKP_FluxFinancierDetail.data.find(
          //   (f) => f.ID_Tarif == tarif.id
          // );

          let groupe = this.nomduGroupe;
          // this.getGroupeCreneaux.map((item) => {
          //   if (this.data.nomGroupe === item.id) {
          //     groupe = item;
          //   }
          // });

          let datatarifsFluxDetail = {};

          if (
            groupe &&
            groupe?.STR_GroupeSaisonCreneau &&
            groupe?.STR_GroupeSaisonCreneau[0]?.MKP_Tarif.id === tarif.id
          ) {
            datatarifsFluxDetail = {
              ID_Structure: user.ID_Structure,
              ID_Utilisateur: user.ID_Utilisateur,
              ID_Saison: this.data.saison,
              ID_Tarif: tarif.id,
              MontantHT: tarif.MontantUnitaire,
              MontantTTC: tarif.MontantUnitaire,
              MontantTVA: 1,
              FIN_CodeAnalytique: 0,
              FIN_CodeCompta: 0,
              NomFluxFinancierDetail: `${groupe && groupe.NomGroupe}`,
            };
          } else {
            if (tarif) {
              datatarifsFluxDetail = {
                ID_Structure: user.ID_Structure,
                ID_Utilisateur: user.ID_Utilisateur,
                ID_Saison: this.data.saison,
                ID_Tarif: tarif.id,
                MontantHT: tarif.MontantUnitaire,
                MontantTTC: tarif.MontantUnitaire,
                MontantTVA: 1,
                FIN_CodeAnalytique: 0,
                FIN_CodeCompta: 0,
                NomFluxFinancierDetail: `${tarif.NomTarif}`,
              };
            }
          }
          fluxUser.MKP_FluxFinancierDetail.data.push(datatarifsFluxDetail);
        });

        fluxUser.MontantHT = this.licenceCount
          ? this.getTotal / this.licenceCount
          : this.getTotal;
        fluxUser.MontantTTC = this.licenceCount
          ? this.getTotal / this.licenceCount
          : this.getTotal;
        return fluxUser;
      });
      this.fluxData = [...data];
    },

    checkEditMultiple() {
      // Si pas plus d'un user, pas la peine de faire le check
      if (this.users.length > 1) {
        // Récuprer le type de licence
        let licence = this.choosenLicence;
        let licenceSlug = licence.SlugTypeLicence;
        let hasErrorsAge = false;
        let hasErrorsLicence = false;
        let userErrorsAge = [];
        let userErrorsLicence = [];
        this.users.forEach((u) => {
          let dateNaissance = u.UTI_Utilisateur.DN_DateNaissance;
          let diffAge = this.$moment().diff(dateNaissance, "years");
          let user = u.UTI_Utilisateur;
          if (u.UTI_Licence && u.UTI_Licence.ID_Saison == this.saison.id) {
            hasErrorsLicence = true;
            userErrorsLicence.push(user);
          } else if (
            (licenceSlug == "adulte" && diffAge < 18) ||
            (licenceSlug == "jeune" && diffAge >= 18)
          ) {
            // Un utilisateur à moin ou plus de 18 ans avec la mauvaise licence
            hasErrorsAge = true;
            userErrorsAge.push(user);
          }
        });
        if (hasErrorsLicence) {
          let message =
            "Les utilisateurs suivants possédent déjà une licence pour la saison <span class='font-weight-bold'>" +
            this.saison.id +
            "</span>:";
          userErrorsLicence.forEach((u) => {
            message +=
              "<div class='font-weight-bold'>" +
              u.CT_Nom +
              " " +
              u.CT_Prenom +
              "</div>";
          });
          this.errors.push({ error: message });
        }
        if (hasErrorsAge) {
          let message =
            "Les utilisateurs suivants ne peuvent pas bénéficier de la licence <span class='font-weight-bold'>" +
            licence.NomTypeLicence +
            "</span>:";
          userErrorsAge.forEach((u) => {
            message +=
              "<div class='font-weight-bold'>" +
              u.CT_Nom +
              " " +
              u.CT_Prenom +
              "</div>";
          });
          this.errors.push({ error: message });
        }

        // si licence jeune, verifier que tous les users ont moins de 18 ans
      }
    },
  },

  props: {
    users: {
      type: Array,
    },

    modalIsOpen: {
      type: Boolean,
      default: false,
    },

    context: {
      type: Object,
    },

    data: {
      type: Object,
    },
    licenceCount: {
      type: Number,
    },
  },
};
</script>
