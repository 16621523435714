<template>
  <v-row>
    <v-col cols="12">
      <h4 v-if="context.fromModal">
        Option(s) d'assurance pour la saison {{ context.ID_Saison }}:
      </h4>
      <h4 v-else>
        Mes options d'assurance pour la saison {{ context.ID_Saison }}:
      </h4>
    </v-col>

    <v-col cols="12" v-if="mounted">
      <span v-if="currentAssurances.length" :key="componentsKey">
        <AssuranceChipItem
          v-for="(assu, index) in currentAssurances"
          :key="index"
          :context="{ ...context, assurance: assu }"
          @chipsSelected="openDialog"
          :deletable="deletable"
          :hasCodeFederal="isFFME"
        />
        <div class="d-flex flex-wrap">
          <v-chip
            v-if="canModifyLicence"
            class="ma-2"
            color="primary"
            @click="updateLicence"
          >
            Modifier la licence
          </v-chip>

          <div v-if="isFederalMode && isClubOrEA && canClubOrEACancel">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-chip
                class="ma-2"
                color="error"
                dark
                v-on="on"
                @click="confirmCancelLicenceDialog = true"
                >
                  Annuler la licence
                </v-chip>
              </template>
              Vous pouvez annuler la licence dans les 24h qui suivent sa création s'il s'agit d'une erreur.
            </v-tooltip>
          </div>

          <div v-if="isFederalMode && isSuperAdmin">
            <v-tooltip bottom v-if="isInTimeRange">
              <template v-slot:activator="{ on }">
                <v-chip
                class="ma-2"
                color="error"
                dark
                v-on="on"
                style="opacity: 0.5;"
                >
                  Annuler la licence
                </v-chip>
              </template>
              L'annulation de licence est désactivée la nuit.
            </v-tooltip>
          <div v-else>
            <v-chip
              class="ma-2"
              color="error"
              dark
              @click="confirmCancelLicenceDialog = true"
            >
              Annuler la licence
            </v-chip>
            </div>
          </div>
        </div>
      </span>

      <div v-if="currentLicence" style="display: flex">
        <a v-if="!context.fromModal" :href="`${templateUrl}`">
          <v-chip class="ma-2" color="primary">
            Télécharger la notice d'assurance</v-chip
          >
        </a>
      </div>

      <span v-if="!currentLicence && isFFME">
        Aucune licence pour la saison en cours.
        <v-btn
          small
          rounded
          class="btn btn-primary"
          @click="renewLicence"
        >
          Renouveler la licence
        </v-btn>
      </span>
    </v-col>
    <PrintFacture ref="facture" :print="print"></PrintFacture>
    <YesNo
      id="yesno-dialog"
      title="CONFIRMATION"
      text="Êtes-vous sûr de vouloir annuler la licence ? Cette action est irréversible."
      maxWidth="360"
      :showDialog="confirmCancelLicenceDialog"
      @answerYes="cancelLicence"
      @answerNo="confirmCancelLicenceDialog = false"
    />
  </v-row>
</template>

<script>
import { getSeasonDocuments } from "../../plugins/seasonService";
import AssuranceChipItem from "@/components/Assurances/Assurances__ChipItem.vue";
import YesNo from "@/components/utils/modals/yes-no.vue";
import { mapActions, mapGetters } from "vuex";
import { bus } from "@/main";
import PrintFacture from "@/components/Structure/Finance/PrintFacture.vue";
import { success_notification, error_notification } from "@/plugins/notifications";
import { getTransactionCancelLicenceBody, insertTransaction, successTransaction } from '@/plugins/transactionService';
import {
  OPTION_TYPE_ASSURANCE,
  OPTION_TYPE_IJ,
  OPTION_TYPE_OPTION_ASSURANCE,
  OPTION_TYPE_OPTION_LICENCE_HC,
} from "@/plugins/productService";
import moment from "moment-timezone";

export default {
  components: {
    AssuranceChipItem,
    PrintFacture,
    YesNo,
  },

  data: () => ({
    mounted: false,
    componentsKey: 0,
    confirmDialog: false,
    payload: null,
    currentAssurances: [],
    deletable: true,
    isFFME: false,
    licence: {},
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
    templateUrl: '',
    confirmCancelLicenceDialog: false,
    seasonDocuments: [],
  }),

  async created() {
    await this.setData();

    // reload assurance item
    bus.$on("reloadAssuranceItem", async () => {
      this.$emit("onUpdate");
    });

    this.seasonDocuments = await getSeasonDocuments(this.currentSaison.id);
    const insurancePolicy = this.seasonDocuments?.documents?.find((document) => document.type === 'insurance_policy_template');

    this.templateUrl = `${process.env.VUE_APP_FFME_BACK_URL}/api/files/season_document/${insurancePolicy?.id ?? ''}`;

    this.mounted = true;
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapGetters("structure", ["hasCodeFederal", "currentStructure", "currentStructureType"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("user", ["getRole", "currentUserId"]),

    currentLicence() {
      if (!this.licences) {
        return null;
      }

      return this.licences[0];
    },
    isFFMEFederal() {
      return (
        this.context.user?.STR_Structure.id === 1318 ||
        (this.context.user?.STR_Structure.id === 2261 &&
          this.context.hasCodeFederal)
      );
    },
    isAdmin() {
      if (!this.isFederalMode) return false;

      if (
        this.getRole?.SlugRole === "club_president" ||
        this.getRole?.SlugRole === "super_administrateur" ||
        this.getRole?.SlugRole === "club_administrateur" ||
        this.getRole?.SlugRole === "ea_administrateur" ||
        this.getRole?.SlugRole === "administrateur_vie_territoriale"
      )
        return true;
      return false;
    },
    isSuperAdmin() {
      if (!this.isFederalMode) return false;
      const superRole = [
        "super_administrateur",
        "administrateur_vie_territoriale",
      ];
      if (superRole.includes(this.getRole?.SlugRole)) return true;
      return false;
    },

    isClubOrEA() {
      if (!this.isFederalMode) {
        return false
      }

      if (['club_association', 'club_etablissement_affilie'].includes(this.currentStructureType.SlugTypeStructure)) {
        return true
      }

      return false;
    },

    canClubOrEACancel() {
      const dateNow = moment().tz("Europe/Paris");
      const dateLicence = moment.tz(this.currentLicence.createdAt, "Europe/Paris");

      const oneDay = 24 * 60 * 60 * 1000;

      if (dateNow.diff(dateLicence) > oneDay) {
        return false;
      }

      return true;
    },

    canModifyLicence() {
      const mode = localStorage.getItem("ffme_user_mode");

      if (mode === "licencie") {
        return true
      }

      if (this.isAdmin) {
        return true
      }

      return false;
    },
    isFederalMode() {
      return localStorage.getItem("ffme_user_mode") === "federal";
    },
    isInTimeRange() {
      const hours = new Date().getUTCHours() + 1;

      return (hours >= 22 || hours < 3);
    }
  },

  methods: {
    ...mapActions("utilisateurs", ["getLicenceUtilisateur"]),
    ...mapActions("auth", ["initRolesAndPermissions"]),
    ...mapActions("structure", ["getStructuresByID"]),

    async setData() {
      await this.initRolesAndPermissions();

      const structureUser = await this.getStructuresByID({
        ID_Utilisateur: this.user.id,
      });

      this.isFFME = structureUser?.some(
        (structure) => structure.STR_Structure.id === 1318
      );

      this.licences = [];
      this.licences = await this.getLicenceUtilisateur({
        user: this.context.ID_Utilisateur,
        season: this.context.ID_Saison,
        'order[season]': 'desc',
        'order[dateEnd]': 'desc',
      });

      if (!this.currentLicence) {
        return;
      }

      //set assurancess ///
      let options = this.currentLicence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_OPTION_ASSURANCE);
      let optionsHC = this.currentLicence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_OPTION_LICENCE_HC);
      let complementaire = this.currentLicence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_IJ);
      let assurance = this.currentLicence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_ASSURANCE )[0] ?? null;

      this.currentAssurances = [assurance, ...options, ...optionsHC, ...complementaire]

      this.$store.commit(
        "licences/SET_CURRENT_ASSURANCES",
        this.currentAssurances
      );
    },
    openDialog(payload) {
      this.payload = payload;
      this.confirmDialog = true;
    },

    async onUpdate(assu) {
      this.$emit("onUpdate", assu);
      await this.setData();
      this.closeModal();
      return;
    },
    printFacture(item) {
      this.$refs.facture.printFacture(item);
    },
    updateLicence() {
      if (localStorage.getItem('ffme_user_mode') === 'licencie') {
        window.location = `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-adherents/modification-de-licence/${this.currentLicence.id}?structureId=${this.currentLicence.structure.id}&appMode=${localStorage.getItem("ffme_user_mode")}`;
      } else if (localStorage.getItem('ffme_user_mode') === 'federal') {
        window.location = `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-adherents/modification-de-licence/${this.currentLicence.id}?structureId=${this.currentStructure.STR_Structure.id}&appMode=${localStorage.getItem("ffme_user_mode")}`;
      }
    },
    async cancelLicence() {
      const transaction = await insertTransaction(getTransactionCancelLicenceBody(this.currentLicence, this.currentUserId));

      if (!transaction.ok) {
        error_notification(transaction.data.message ?? 'Une erreur est survenue lors de la création de la transaction');
        this.confirmCancelLicenceDialog = false;
        return;
      }

      const success = await successTransaction(transaction.data.id);

      if (!success.ok || success.data.status !== 'success') {
        error_notification('Une erreur est survenue lors de la validation de la transaction');
        this.confirmCancelLicenceDialog = false;
        return;
      }

      success_notification('La licence à bien été annulée');

      this.confirmCancelLicenceDialog = false;

      let back = Buffer.from(this.$route.query.from, 'base64').toString('ascii');

      setTimeout(() => {
        window.location = `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/utilisateurs?appMode=${localStorage.getItem("ffme_user_mode")}${back ? '&' + back : ''}`;
      }, 2000);
    },
    renewLicence() {
      window.location = `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/saisir-une-licence/${this.context.ID_Saison}/${this.context.ID_Utilisateur}?structureId=${this.currentStructure.STR_Structure.id}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>
