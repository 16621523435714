<template>
  <v-dialog
    v-if="modalIsOpen"
    v-model="modalIsOpen"
    max-width="600px"
    persistent
  >
    <v-tabs background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>

      <v-tab style="display: flex; flex-direction: column">
        <span style="font-size: 1.2rem">
          {{ modalTitle }}
        </span>
      </v-tab>
      <v-icon class="close-icon" @click="close">mdi-close</v-icon>
    </v-tabs>
    <div class="modal__content" v-if="mounted">
      <v-tabs-items>
        <v-card flat>
          <ValidateForm
            @validated="openConfirmModal"
            :loading="loading"
            style="width: 100%"
          >
            <!-- <v-col v-if="dataFromLastSeason"
              >Informations de la saison précedente!</v-col
            > -->

            <v-col cols="12">
              <div v-if="hasGlobalError">
                <ul class="text-center">
                  <li
                    style="font-size: 0.8rem; color: red"
                    v-for="(errorMessage, index) in globaleErrorMessage"
                    :key="index"
                  >
                    {{ errorMessage }}
                  </li>
                </ul>
                <v-btn @click.prevent="close()" color="red" class="mx-2" rounded
                  >Annuler</v-btn
                >
              </div>

              <div v-if="!hasGlobalError">
                <selectInput
                  :items="saisonsList"
                  v-model="saison"
                  rules="required"
                  item_text="NomSaison"
                  item_value="id"
                  label="Saison"
                  @onChange="onChangeSaison"
                ></selectInput>

                <selectInput
                  :items="licenceListItem"
                  v-model="licence"
                  rules="required"
                  item_text="NomTypeLicence"
                  @onChange="onChangeLicence"
                  item_value="id"
                  label="Type de licence"
                ></selectInput>
                <selectInput
                  :items="assuranceListItem"
                  v-model="assurance"
                  rules="required"
                  item_text="NomTypeAssurance"
                  @onChange="onChangeAssu"
                  item_value="id"
                  label="Type d'assurance"
                ></selectInput>

                <selectInput
                  :items="assuranceOptionListItem"
                  v-model="assuranceOption"
                  item_text="NomTypeAssurance"
                  item_value="id"
                  label="Option d'assurance"
                ></selectInput>
                <v-card-actions class="px-10 pb-4">
                  <v-spacer></v-spacer>
                  <v-btn
                    @click.prevent="close()"
                    color="red"
                    class="mx-2"
                    rounded
                    >Annuler</v-btn
                  >
                  <v-btn
                    type="submit"
                    :loading="loading"
                    small
                    rounded
                    class="btn btn-primary"
                    >Enregistrer</v-btn
                  >
                </v-card-actions>
              </div>
            </v-col>
          </ValidateForm>
        </v-card>
      </v-tabs-items>
    </div>
    <ConfirmLicenceSiege
      v-if="modalConfirmTarifIsOpen"
      :modalIsOpen="modalConfirmTarifIsOpen"
      @onClose="modalConfirmTarifIsOpen = false"
      @onConfirm="submit"
      :users="users"
      :data="{
        tarif: null,
        saison: saison,
        licence: licence,
        assurance: assurance,
        optionAssurance: assuranceOption,
      }"
    />
    <PrintFacture ref="facture" :print="print"></PrintFacture>
  </v-dialog>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable  no-unexpected-multiline */
import ValidateForm from '@/components/utils/form.vue';
import selectInput from '@/components/utils/select.vue';
import modalUserDetail from '@/components/utils/modals/User/User__Modal.vue';
import PrintFacture from '@/components/Structure/Finance/PrintFacture.vue';
import { send_email } from '@/plugins/utils';
import ConfirmLicenceSiege from '@/components/utils/modals/Licence/LicenceSiegeConfirm__Modal.vue';
// import InputText from "@/components/utils/input.vue"
import {
  error_notification,
  success_notification,
} from '@/plugins/notifications';
import { sleep } from '@/plugins/utils';
import { mapActions, mapGetters } from 'vuex';
import { bus } from '@/main';
export default {
  components: {
    ValidateForm,
    selectInput,
    ConfirmLicenceSiege,
    PrintFacture,
  },
  data: () => ({
    loading: false,
    mounted: false,

    licenceListItem: [],
    licence: null,

    assuranceListItem: [],
    assurance: null,

    assuranceOptionListItem: [],
    assuranceOption: null,

    users: [],
    modalConfirmTarifIsOpen: false,

    hasGlobalError: false,
    globaleErrorMessage: [],
    saisonsList: [],
    saison: null,
    count: 0,
    already: false,
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
  }),
  async created() {
    this.users = [this.user];

    await this.setData();
    this.mounted = true;
    console.log(this.users[0]);
  },
  mounted() {},

  computed: {
    ...mapGetters('saisons', ['currentSaison', 'nextSaison']),
    ...mapGetters("structure", [
      "currentStructureId",
    ]),
    ...mapGetters('user', ['currentUserId']),
    modalTitle() {
      if (this.users.length == 1) {
        return (
          'Licence siège pour ' +
          this.users[0].UTI_Utilisateur.CT_Nom +
          ' ' +
          this.users[0].UTI_Utilisateur.CT_Prenom
        );
      } else {
        return 'LICENCES EN SERIE';
      }
    },
  },
  methods: {
    ...mapActions('structure', [
      'updateLicenceUtilisateurStructureList',
      'getAffiliationTarifStructure',
      'updateLastLicenceUtilisateur',
    ]),
    ...mapActions('utilisateurs', [
      'getLicenceUtilisateur',
      'getFluxFinancierUtilisateur',
      'createFluxFinancierDetail',
      'deleteFluxFinancierDetail',
    ]),
    ...mapActions('basics', ['getListTypeLicence', 'getListTypeAssurances']),
    ...mapActions('tarifs', ['getTarifsAssurance']),

    async setData() {
      let response = await this.getLicenceUtilisateur({
        ID_Utilisateurs: [this.user.ID_Utilisateur],
      });

      if (response) {
        // check if we have a licence for the saison selecyted by default
        this.allLicences = response;
      }
      await this.setSaisons();
      await this.setFamilleTarif(); // licence gratuite
      await this.setLicenceType();
      await this.setAssurance();

      this.checkSaison();

      return;
    },

    async checkSaison() {
      if (this.saison) {
        let response = await this.getLicenceUtilisateur({
          ID_Utilisateurs: [this.user.ID_Utilisateur],
        });
        return response.find(
          (lic) =>
            lic.ID_Utilisateur == this.users[0].ID_Utilisateur &&
            lic.ID_Saison == this.saison
        );
      } else {
        return false;
      }
    },

    close() {
      this.$emit('onClose');
    },

    async openConfirmModal() {
      // //this.formatData();
      let hasLicence = await this.checkSaison();
      if (hasLicence != false && hasLicence != undefined) {
        error_notification(
          "Erreur: L'utilisateur possède déjà une licence sur cette saison."
        );
      } else {
        console.log(this.optionAssu);
        this.modalConfirmTarifIsOpen = true;
      }
    },

    async setSaisons() {
      if (this.nextSaison) {
        this.saisonsList = [this.currentSaison, this.nextSaison];
      } else {
        this.saisonsList = [this.currentSaison];
      }
    },

    async onChangeSaison() {
      await this.checkSaison();
      console.warn('changement saison');
      let hasLicence = await this.checkSaison();
      if (hasLicence) {
        console.warn('DÉJA UNE LICENCE POUR CETTE SAISON');
        console.log(this.licence);
        this.licence = null;
        this.assurance = null;
        this.assuranceOption = null;
        this.hasGlobalError = true;
        this.globaleErrorMessage = [
          "L'utilisateur possède déjà une licence pour cette saison.",
        ];
      } else {
        this.hasGlobalError = false;
        this.globaleErrorMessage = [];
        this.licence = this.licenceListItem[0];
        this.assurance = this.assuranceListItem[0];
        this.assuranceOption = this.assuranceOptionListItem[0];
      }
    },
    async removeFormError() {
      await this.setLicenceType();
      await this.setAssurance();
      this.checkSaison();
    },
    // Get/Set the famille tarif licence
    async setFamilleTarif() {
      // return the only tarif available here
      // licence gratuite by ffme
      // + ADULTE + SKI
      this.tarifs = await this.getAffiliationTarifStructure(this.context);
      this.tarif = this.tarifs[3];
      return;
    },
    async setLicenceType() {
      let licences = await this.getListTypeLicence();
      this.licenceListItem = licences.filter(
        (licence) => licence.SlugTypeLicence === 'adulte'
      );
      console.log(this.licenceListItem);
    },
    async setAssurance() {
      let assurances = await this.getListTypeAssurances();
      this.assuranceListItem = assurances.filter(
        (assurance) => assurance.SlugTypeAssurance === 'assurance_base++'
      );
      this.assuranceOptionListItem = assurances.filter(
        (assurance) => assurance.SlugTypeAssurance === 'assurance_alpinisme'
      );
    },
    onChangeLicence(item) {
      this.tarif.ID_TypeLicence = item;
    },
    onChangeAssu(item) {
      this.tarif.ID_TypeAssurance = item;
    },

    async prepareFluxFinancier(response, users, confirmResponse) {
      // console.log(response)
      // console.log(users)
      // console.log(confirmResponse.fluxData)

      if (confirmResponse.fluxData[0].id) {
        await this.deleteFluxFinancierDetail({
          ID_FluxFinancier: confirmResponse.fluxData[0].id,
        });
      }
      const insertedFlux = await this.createFluxFinancierDetail({
        flux: confirmResponse.fluxData,
      });

      this.printFacture(insertedFlux);

      return;
    },
    async submit(confirmResponse) {
      let IDSToRemove = [];
      const fetch = require('node-fetch');

      let usersData = this.users.map((user) => {
        //Get the assurance base ++ data
        let assurances = [];
        assurances.push({
          ID_Saison: this.saison,
          ID_Utilisateur: user.ID_Utilisateur,
          ID_Tarif: null,
          ID_TypeAssurance: this.assurance.id,
        });

        if (this.assuranceOption) {
          assurances.push({
            ID_Saison: this.saison,
            ID_Utilisateur: user.ID_Utilisateur,
            ID_Tarif: null,
            ID_TypeAssurance: this.assuranceOption.id,
          });
        }

        let payload = {};

        // set globals variables mutation
        let selectedSaisonEnd = this.saisonsList.find(
          (s) => s.id === this.saison
        ).dateEnd;
        let variables = {
          ID_Saison: this.saison,
          ID_Structure: 1318, // ID FFME par défaut car licence siège
          ID_TypeLicence: this.licence.id,
          ID_Utilisateur: user.ID_Utilisateur,
          ID_Tarif: this.tarif.id,
          ID_EtapeLicence: 1,
          EST_LicenceSiege: true,
          DateLicenceValidation: this.$moment(),
          DateLicenceFinValidite: this.$moment.utc(selectedSaisonEnd),
          created_by_structure: this.currentStructureId,
          created_by_user: this.currentUserId,
          creation_type: 'MANUEL',
          UTI_LicenceAssurance: {
            data: assurances,
            on_conflict: {
              constraint: 'UTI_LicenceAssurance_pkey',
              update_columns: [
                'DateSouscriptionAssurance',
                'ID_Tarif',
                'ID_Saison',
              ],
            },
          },
        };

        return { ...payload, ...variables };
      });

      console.log('users data', usersData);

      try {
        this.loading = true;
        const response = await this.updateLicenceUtilisateurStructureList({
          ID_Structure: this.users[0].ID_Structure,
          items: usersData,
          rmID_UTI_LicenceAssurance: IDSToRemove,
          estLicencie: true,
        });

        console.log('Utilisateur', JSON.stringify(response));

        // console.log("response save", response)

        if (response) {
          let usersToUpLastLicence = [];
          this.users.forEach(async (user) => {
            if (
              !user.UTI_Licence ||
              (user.UTI_Licence && user.UTI_Licence.ID_Saison < this.saison)
            ) {
              delete user.UTI_Licence;
              delete user.UTI_Utilisateur;
              delete user.STR_Structure;
              delete user.ADM_RoleType;
              delete user.__typename;
              response.forEach((respo) => {
                if (user.ID_Utilisateur == respo.ID_Utilisateur) {
                  // console.log('user doublon', user)
                  user.EST_Actif = true;
                  user.ID_LastLicence = respo.id;
                }
              });

              usersToUpLastLicence.push(user);

              // RAFENITRA
              try {
                await this.sendEmail(usersData);
              } catch (e) {
                console.log('error', e);
              }
            }
          });

          console.log('users to update', usersToUpLastLicence);
          await this.updateLastLicenceUtilisateur({
            data: usersToUpLastLicence,
          });

          // const response = await fetch('https://www.montagne-escalade.com/site/BO/ADM_import_HISTORIQUELICENCE.php', {
          //   method: 'post',
          //   body: JSON.stringify(usersToUpLastLicence),
          //   headers: {'Content-Type': 'application/json'}
          // });
          // const json = await response.json();
          // if(response.status == 200) {
          //   console.log('requete envoyé')
          //   //await this.storeNewUser(json.message)
          // } else{
          //   error_notification(json.message)
          //   console.log(json.message)
          // }
        }

        console.log('flux object', confirmResponse);
        await this.prepareFluxFinancier(response, this.users, confirmResponse);
        this.loading = false;
        success_notification('Licence et assurances mis à jour!');
        this.modalConfirmTarifIsOpen = false;
        bus.$emit('onUserListStructureUpdated');
        this.$emit('onUpdate', this.users[0]);
        this.close();
      } catch (e) {
        console.log(e);
        error_notification('Oups, erreur');
      }
    },

    //Sendemail licence siege
    async sendEmail(usersData) {
      usersData.forEach(async (user) => {
        const fullDataUser = await this.getUtilisateurFullData(
          user.ID_Utilisateur
        );

        console.log('fullDataUser ---- email', fullDataUser);
        let payload = {
          User: user.ID_Utilisateur, // l'utitlisateur cible
          Structure: this.currentStructure.STR_Structure.NomStructure, // la str cible
          // Structure: this.context.user.STR_Structure.NomStructure, // la str cible
          Email: fullDataUser.UTI_Utilisateurs[0].CT_Email,
          ID_Structure: this.currentStructureId.toString(),
          Saison: this.saison.toString(),
          Context: 'addLicenceToUser',
          SlugModule: 'gestion_des_licences__saisir_une_licence',
        };
        console.log('payload licence siege email', payload);
        //NOTIFICATION "Délivrance licence (notice d'assurance)"
        await send_email(payload);
      });
    },

    printFacture(item) {
      this.$refs.facture.printFacture(item);
    },
  },
  props: {
    modalIsOpen: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
    context: {
      type: Object,
    },
    itemsSelected: {
      type: Array,
    },
    renewLicence: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-list-item--disabled {
  /* color: rgba(0, 0, 0, 0.87); */
}
</style>
